import React, { Component } from 'react';
import '../../css/directions.css';

export default class Directions extends Component {

  render() {
    const isContestant = this.props.isContestant;

    return (
      <div className="directions-container">
        <h1>Let's scroll you through how Pinnies works.</h1>
        {isContestant && this.Contestant()}
        {!isContestant && this.Voter()}
      </div>
    );
  }

  Voter() {
    return (
      <div>
        <div className="row flex-center">
          <div><img src={require('../../img/screenshots/contests.jpg')} alt="" className="screenshot" /></div>
          <p className='padding-left'>Click on a gallery to see Pinnies & their contest entries.</p>
        </div>

        <h3>Allow email notifications on your Apple/Android device.</h3>
        <h3>Email notifications will keep you informed of new contests.</h3>

        <div className="row flex-center">
          <p className='padding-right'>Scroll through a World of Glamour!<br />Follow & Vote for your favorite Pinnies!</p>
          <div><img src={require('../../img/screenshots/swipe-and-vote.jpg')} alt="" className="screenshot" /></div>
        </div>

        <div className="row flex-center">
          <div><img src={require('../../img/screenshots/vote.jpg')} alt="" className="screenshot" /></div>
          <div className='padding-left'>
            <p>Vote LIVE for multiple Pinnies!<br />Cast your votes.</p>
            <img className='lovit-icon' src={require('../../img/lovit.png')} alt="" />
            <p>Or return to the gallery.</p>
          </div>
        </div>

        {this.Shared()}
      </div>
    )
  }

  Contestant() {
    return (
      <div>
        <div className="row flex-center">
          <div><img src={require('../../img/screenshots/portfolio.jpg')} alt="" className="screenshot" /></div>
          <p className='padding-left'>Build a portfolio.<br/>Enter contests.<br/>Watch your votes grow.</p>
        </div>

        <h3>Allow email notifications on your Apple/Android device.</h3>
        <h3>Email notifications keep you informed of new contests.<br/>Submit your entry for Gatekeeper Review before the deadline.</h3>

        <div className="row flex-center">
          <p className='padding-right'>The sooner you enter a new contest, the better.<br/>Your entry can be at the Top of a Live Gallery, thus increasing your odds for more views and votes!</p>
          <div><img src={require('../../img/screenshots/enter-contests.jpg')} alt="" className="screenshot"/></div>
        </div>
        
        <h3>A denied entry due to violation of contest rules will result in a new entry request.<br/>You will lose your initial spot within the contest galley and be placed in the order in which your new entry is received.</h3>

        <div className="row flex-center">
          <div><img src={require('../../img/screenshots/vote.jpg')} alt="" className="screenshot"/></div>
          <div className='padding-left'>
            <p>Vote LIVE for multiple Pinnies if you dare!<br/>Cast your votes.</p>
            <img className='lovit-icon' src={require('../../img/lovit.png')} alt=""/>
            <p>Or return to the gallery.</p>
          </div>
        </div>
      
        {this.Shared()}
      </div>
    )
  }

  Shared() {
    return (
      <>
        <div className="row flex-center">
          <p className='padding-right'>LIVE SCORES are tracked in real time.</p>
          <div><img src={require('../../img/screenshots/scores.jpg')} alt="" className="screenshot" /></div>
        </div>

        <h3>Top Voted Pinnies are placed in brackets & awarded prize money at the end of each contest!</h3>
        {this.Awards()}

        <h3>1st Place Contest Winners:</h3>
        <p>A "CONTEST Winner” is placed in the Diamond bracket. The Highest Prize Money is awarded for Diamond trophy idols & advancement to the Pinnies Pennant will be awarded.</p>
        <h3>2nd Place:</h3>
        <p>“1st Runner Ups” are placed in the Platinum bracket. Prize Money & a Platinum trophy idol will be awarded along with advancement to the Pinnies Pennant.</p>
        <h3>3rd Place:</h3>
        <p>“2nd Runner Ups” are placed in a Gold Bracket. Prize Money & a Gold trophy idol will be awarded along with advancement to the Pinnies Pennant.</p>
        <h3>Ranks 4-20:</h3>
        <p>“Wild Card” Pinnies are placed in a Silver bracket. A Silver trophy idol & advancement to the Pinnies Pennant will be awarded.</p>
        <p style={{paddingTop: 40}}>Each additional Pinnie will receive a Pink trophy idol for every contest entry. They are “In the Hunt” for prize money & a Pinnies Pennant Invitation.</p>

        <img src={require('../../img/pinnie-of-the-year.png')} alt="" className="pinnie-of-the-year"/>

        <h3>Pinnies that place in the Diamond, Platinum, Gold & Silver brackets will have an opportunity to compete in the Pinnies Pennant at the end of a Series of Contests.</h3>
        <h3>The Top Voted Pinnie will receive Grand Prize Money & The Pinnies Diamond Pendant!</h3>
      </>
    )
  }

  Awards() {
    return (
      <div className="awards-container flex-center">
        <div>
          <div className='award'>
            <img src={require('../../img/awards/diamond.png')} alt=""/>
            <p>DIAMOND</p>
          </div>
          <p>CONTEST<br/>WINNERS</p>
        </div>
        <div>
          <div className='award'>
            <img src={require('../../img/awards/platinum.png')} alt=""/>
            <p>PLATINUM</p>
          </div>
          <p>1ST<br/>RUNNER UPS</p>
        </div>
        <div>
          <div className='award'>
            <img src={require('../../img/awards/gold.png')} alt=""/>
            <p>GOLD</p>
          </div>
          <p>2ND<br/>RUNNER UPS</p>
        </div>
        <div>
          <div className='award'>
            <img src={require('../../img/awards/silver.png')} alt=""/>
            <p>SILVER</p>
          </div>
          <p>WILD<br/>CARDS</p>
        </div>
        <div>
          <div className='award'>
            <img src={require('../../img/awards/pink.png')} alt=""/>
            <p>PINK</p>
          </div>
          <p>IN THE<br/>HUNT</p>
        </div>
      </div>
    )
  }
}

