import { getAuditions, createAudition } from '../services/pinnies_api';
import { receiveProfile } from './profile_actions';

export const RECEIVE_AUDITIONS = 'RECEIVE_AUDITIONS';
export const RECEIVE_AUDITION = 'RECEIVE_AUDITION';

export const receiveAuditions = auditions => ({
  type: RECEIVE_AUDITIONS,
  auditions
});
export const receiveAudition = audition => ({
  type: RECEIVE_AUDITION,
  audition
});



export const fetchAuditions = images => async dispatch => {
  try {
    let auditions = await getAuditions();//await Parse.Cloud.run('fetchSubmittedApplications', {});
    //auditions = auditions.map(a => a.toJSON());

    return dispatch(receiveAuditions(auditions));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const submitAudition = images => async (dispatch, getState) => {
  try {
    // const img1 = new Parse.File('img.jpg', { base64: images[0] });
    // const img2 = new Parse.File('img.jpg', { base64: images[1] });
    // const img3 = new Parse.File('img.jpg', { base64: images[2] });
    
    // await img1.save();
    // await img2.save();
    // await img3.save();
    //await Parse.Cloud.run('submitApplication', {images: [img1, img2, img3]});
    const audition = await createAudition({ images });
    
    dispatch(receiveAuditions([...getState().entities.auditions.auditionsList, audition]));
    return dispatch(receiveAudition(audition));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const becomeVoter = () => async dispatch => {
  try {
    const profile = {};//await Parse.Cloud.run('becomeVoter', {});

    return dispatch(receiveProfile(profile));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};