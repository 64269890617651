import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import Loader from '../shared/loader';
import '../../css/login.css';
import { resetUserPassword } from '../../actions/user_actions';
import { useEffect } from 'react';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    token: '',
    loading: false,
    success: false,
    passwordsNotMatch: false
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setState({...state, email: query.get('email'), token: query.get('token')});
  }, []);

  const handleValueChange = (e, field) => {
    let value = e.target.value;
    
    let passwordsNotMatch = field == 'confirmPassword' && value != '' && value != state.password;

    setState({...state, [field]: value, passwordsNotMatch });
  };

  const doResetPassword = async (e) => {
    e.preventDefault();
    setState({...state, loading: true});
    await dispatch(resetUserPassword(state.email, state.token, state.password));
    setState({...state, loading: false});
    history.push('/login');
  };

  return (
    <div className="login-container">
      <img src={require('../../img/logo-with-text.png')} alt=""/>
      
      <form onSubmit={doResetPassword}>
          <input 
            type="email" 
            placeholder='EMAIL' 
            readOnly={true}
            value={state.email} 
            onChange={(e) => handleValueChange(e, 'email')} 
            className={state.isSignup && state.errors.email ? 'error' : ''} 
            autoCorrect="off" 
            autoCapitalize="off"
          />
          
          <input 
            type="password" 
            placeholder='PASSWORD' 
            value={state.password} 
            onChange={(e) => handleValueChange(e, 'password')} 
          />
          
          <input 
            type="password" 
            placeholder='CONFIRM PASSWORD' 
            value={state.confirmPassword} 
            onChange={(e) => handleValueChange(e, 'confirmPassword')} 
            className={state.passwordsNotMatch ? 'error' : ''} 
          />
        
        <input className='btn' type="submit" value='RESET PASSWORD' />
      </form>

      <Loader loading={state.loading} fullscreen={true} />
    </div>
  );
};

export default PasswordReset;