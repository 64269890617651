import { fetchUserProfile, receiveMyProfile } from './profile_actions';
import { fetchInteractions } from './interaction_actions'; 
import { fetchProfileImages } from './image_actions';
import { fetchAwards } from './award_actions';
import { setUiRedirectPath, catchError, clearErrors } from './ui_actions';
import { loginUser, registerUser, getLoggedOnUser, requestStripeSubscription, createSubscription, logoutUser, deleteUser, verifyEmail, sendEmailVerificationRequest, sendPasswordResetRequest, resetPassword } from '../services/pinnies_api';

export const RECEIVE_USER = 'RECEIVE_USER';
export const LOGOUT = 'LOGOUT';

export const receiveUser = user => ({
  type: RECEIVE_USER,
  user
});

export const receiveLogout = () => ({
  type: LOGOUT
});

export const logout = () => dispatch => {
  clearErrors();
  logoutUser();
  localStorage.removeItem('isSubscribed');
  localStorage.removeItem('profile');
  dispatch(receiveMyProfile(null));
  return dispatch(receiveLogout());
};

// setTimeout(() => logout(), 100);


export const login = (username, password, redirect) => async dispatch => {
  try {
    clearErrors();
    let token = await loginUser(username.toLowerCase(), password);
    if (token && !token.error) { 
      const user = await dispatch(fetchCurrentUser());
      // let isSubscribed = await Parse.Cloud.run('checkForSubscrition', {platform: 'browser'});
      // user = user.toJSON();
      // user.isSubscribed = isSubscribed;
      // localStorage.setItem('isSubscribed', isSubscribed, { expires: 1000 });

      // dispatch(fetchInteractions(profile));
      // dispatch(fetchProfileImages(profile));
      // dispatch(fetchAwards(profile));

      dispatch(receiveUser(user));

      if (redirect)
        dispatch(setUiRedirectPath(redirect));
      
      return user;
    }
    else {
      dispatch(receiveUser(null));
      throw new Error(token.error);
    }
  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const signup = (email, username, password, dob, userType) => async dispatch => {
  try {
    clearErrors();
    await registerUser({
        email: email.toLowerCase(),
        username: username.toLowerCase(),
        password: password,
        dob: new Date(dob),
        userType: userType.toUpperCase()
      });

    const user = await dispatch(fetchCurrentUser());
    
    dispatch(receiveUser(user));
    return user;

  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const verifyEmailAddress = (email, token) => async dispatch => {
  try {
    clearErrors();
    const response = await verifyEmail(email, token);

    if (response.success) {
      const user = await dispatch(fetchCurrentUser());
    
      dispatch(receiveUser(user));
    }
    else {
      throw Error(response.error);
    }

  } catch (err) {
    console.log(err);
  }
};

export const sendEmailVerification = () => async dispatch => {
  try {
    clearErrors();
    const response = await sendEmailVerificationRequest();

  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};


export const fetchCurrentUser = () => async dispatch => {
  try {
    clearErrors();
    let user = await getLoggedOnUser();
    user.id = user._id;

    let isSubscribed = user.role == 'SUBSCRIBED';
    
    localStorage.setItem('isSubscribed', isSubscribed, { expires: 1000 });
    dispatch(fetchUserProfile(user.id, true));
    dispatch(receiveUser(user));
    return user;
  } catch (err) {
    if (err.code === 209) {
      dispatch(logout());
      return;
    }
    console.log(err.code);
    dispatch(catchError(err));
  }
};

export const requestSubscription = () => async (dispatch, getState) => {
  try {
    clearErrors();
    return dispatch(requestStripeSubscription());
  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const subscribe = token => async (dispatch, getState) => {
  try {
    clearErrors();
    const subscription = await createSubscription(token, 'stripe');

    dispatch(fetchCurrentUser());
    return subscription;

  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const updateUser = user => async dispatch => {
  try {
    clearErrors();
    const updatedUser = user;//new Parse.User({...user});
    //await updatedUser.save();

    return dispatch(fetchCurrentUser());
  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const sendPasswordReset = email => async dispatch => {
  try {
    clearErrors();
    await sendPasswordResetRequest(email);
    alert('Success! Please check your email.');
  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const resetUserPassword = (email, token, password) => async dispatch => {
  try {
    clearErrors();
    await resetPassword(email, token, password);
    alert('Success! Password has been reset.');
  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};

export const deleteAccount = () => async dispatch => {
  try {
    clearErrors();
    //await new Parse.Cloud.run('deleteMyAccount', {});

    return dispatch(logout());
  } catch (err) {
    console.log(err);
    dispatch(catchError(err));
  }
};