import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import ProfileRow from '../shared/profile_row';
import Paywall from '../shared/paywall';
import Modal from '../shared/modal';
import { fetchLeaderboard } from '../../actions/leaderboard_actions';

import '../../css/leaderboard.css';

const Tabs = ({tab, onTabClick}) => {

  return (
    <div className="tabs-container">
      <div style={{flex: 1}}>
        <div className="underline">
          <div className={"tab" + (tab === 'diamond' ? ' active' : '')} onClick={() => onTabClick('diamond')}>
            <img src={require('../../img/awards/diamond.png')} alt=""/>
            <p>DIAMOND</p>
          </div>
        </div>
        {tab === 'diamond' && <p className="sub-text center-text">CONTEST WINNERS</p>}
      </div>
      <div style={{flex: 1}}>
        <div className="underline">
          <div className={"tab" + (tab === 'platinum' ? ' active' : '')} onClick={() => onTabClick('platinum')}>
            <img src={require('../../img/awards/platinum.png')} alt=""/>
            <p>PLATINUM</p>
          </div>
        </div>
        {tab === 'platinum' && <p className="sub-text center-text">FIRST RUNNER UPS</p>}
      </div>
      <div style={{flex: 1}}>
        <div className="underline">
          <div className={"tab" + (tab === 'gold' ? ' active' : '')} onClick={() => onTabClick('gold')}>
            <img src={require('../../img/awards/gold.png')} alt=""/>
            <p>GOLD</p>
          </div>
        </div>
        {tab === 'gold' && <p className="sub-text center-text">SECOND RUNNER UPS</p>}
      </div>
      <div style={{flex: 1}}>
        <div className="underline">
          <div className={"tab" + (tab === 'silver' ? ' active' : '')} onClick={() => onTabClick('silver')}>
            <img src={require('../../img/awards/silver.png')} alt=""/>
            <p>SILVER</p>
          </div>
        </div>
        {tab === 'silver' && <p className="sub-text center-text">WILD CARDS</p>}
      </div>
      <div style={{flex: 1}}>
        <div className="underline">
          <div className={"tab" + (tab === 'pink' ? ' active' : '')} onClick={() => onTabClick('pink')}>
            <img src={require('../../img/awards/pink.png')} alt=""/>
            <p>PINK</p>
          </div>
        </div>
        {tab === 'pink' && <p className="sub-text center-text">IN THE HUNT</p>}
      </div>
    </div>
  );
}

const Leaderboard = () => {
  const paywall = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const leaderboard = useSelector(state => state.entities.leaderboard);
  const me = useSelector(state => state.entities.profiles.profile);
  const isSubscribed = useSelector(state => state.user.isSubscribed);
  const [state, setState] = useState({
    tab: 'diamond',
    showPaywall: false,
  });
  
  useEffect(() => {
    if (!leaderboard.diamond) {
      dispatch(fetchLeaderboard('diamond'));
    }
  }, []);
  
  useEffect(() => {
    dispatch(fetchLeaderboard(state.tab));
  }, [state.tab]);

  useEffect(() => {
    if (isSubscribed) {
      paywall.close();
    }
  }, [isSubscribed]);

  if (!leaderboard) return null;

  const changeTab = (tab) => {
    setState({...state, tab});
  };

  const goToProfile = (profile) => {
    if (isSubscribed)
        history.push('/user/' + profile.username);
      else
        setState({...state, showPaywall: true});
  };

  if (!leaderboard[state.tab]) return null;

  return (
    <div className='leaderboard-container'>
      <h1>{moment().subtract(1, 'month').format('YYYY')} QUALIFIER LEADERBOARD</h1>

      <Tabs tab={state.tab} onTabClick={changeTab} />

      <div className="wrapper">
        {leaderboard[state.tab].map(profile => (
          <ProfileRow key={profile._id} profile={profile} voteCount={profile.totalVotes} onClick={() => goToProfile(profile)} />
        ))}
      </div>

      {me && me.userType === 'CONTESTANT' && 
        <div className="my-placement-container">
          <ProfileRow key={me._id} profile={me} voteCount={me.totalVotes} onClick={() => goToProfile(me)} />
        </div>
      }

      {state.showPaywall &&
        <Modal ref={paywall} onClose={() => setState({...state, showPaywall: false})}>
          <Paywall />
        </Modal>
      }
    </div>
  );

}

export default Leaderboard;