import { RECEIVE_CONTESTS, RECEIVE_CONTEST } from '../actions/contest_actions';
import { LOGOUT } from '../actions/user_actions';

export default (state={}, action) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_CONTESTS: {
      const newState = {
        ...state,
        contestsList: action.contests
      };
      console.log(newState);
      return newState;
    }
    case RECEIVE_CONTEST: {
      return {
        ...state, 
        currentContest: action.contest
      };
    }
    case LOGOUT: {
      return {};
    }
    default: return state;
  }
}