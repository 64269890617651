import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../shared/modal';
import Cropper from '../shared/cropper';
import Countdown from '../shared/countdown';
import Loader from '../shared/loader';
import PhotoRulesContent from './photo_rules';
import { useLocation } from 'react-router';
import { fetchContestEntries } from '../../actions/image_actions';
import { uploadContestEntry, agreeToContestRules } from '../../actions/contest_actions';


const Status = ({isApproved, isRejected, isPending}) => {
  if (!isApproved && !isRejected && !isPending) return null;

  const text =  isApproved ? 'ENTRY APPROVED!' : 
                isRejected ? 'NOT APPROVED - PLEASE READ THE PHOTO RULES AND RESUBMIT' : 
                isPending ? 'ENTRY PENDING APPROVAL' : '';

  const statusClass = isApproved ? ' approved' :
                isRejected ? ' rejected' :
                isPending ? ' pending' : '';

  return (
    <div className={"entry-status" + statusClass} >
      <p>{text}</p>
    </div>
  );
}

const Awards = ({prizes}) => {
  const { diamond, platinum, gold, silver } = prizes;

  return (
    <div className="contest-awards-container">
      <div className='award'>
        <img src={require('../../img/awards/diamond.png')} alt="" />
        <p>${diamond}</p>
      </div>
      <div className='award'>
        <img src={require('../../img/awards/platinum.png')} alt="" />
        <p>${platinum}</p>
      </div>
      <div className='award'>
        <img src={require('../../img/awards/gold.png')} alt="" />
        <p>${gold}</p>
      </div>
      <div className='award'>
        <img src={require('../../img/awards/silver.png')} alt="" />
        <p>${silver}</p>
      </div>
    </div>
  );
}

const ImagePreview = ({image, onPositionChange}) => {
  const preview = useRef(null);
  const [state, setState] = useState({
    croppedImage: null,
    aspectRatio: null,
    watermarkLocation: 'bottomRight',
  });
  const containerClass = 'contest-entry-preview' + (state.aspectRatio === 4/3 ? ' landscape' : ' portrait')

  const onLogoButton = (location) => {
    setState({...state, watermarkLocation: location});
    onPositionChange();
  };

  return (
    <div className={containerClass} ref={preview}>
      <img src={image} alt=""/>

      {state.croppedImage && 
        <div className="logo-overlay">
          <button 
            className={'logo-placement-btn'}
            style={{backgroundImage: state.watermarkLocation === 'topLeft' ? `url(${require('../../img/logo.png')}` : 'none'}} 
            onClick={() => onLogoButton('topLeft')}
          >
            {state.watermarkLocation !== 'topLeft' && <div className="text"><p>Click to place Pinnies logo</p></div>}
          </button>
          <button 
            className={'logo-placement-btn'}
            style={{backgroundImage: state.watermarkLocation === 'topRight' ? `url(${require('../../img/logo.png')}` : 'none'}} 
            onClick={() => onLogoButton('topRight')}
          >
            {state.watermarkLocation !== 'topRight' && <div className="text"><p>Click to place Pinnies logo</p></div>}
          </button>
          <button 
            className={'logo-placement-btn'}
            style={{backgroundImage: state.watermarkLocation === 'bottomLeft' ? `url(${require('../../img/logo.png')}` : 'none'}} 
            onClick={() => onLogoButton('bottomLeft')}
          >
            {state.watermarkLocation !== 'bottomLeft' && <div className="text"><p>Click to place Pinnies logo</p></div>}
          </button>
          <button 
            className={'logo-placement-btn'}
            style={{backgroundImage: state.watermarkLocation === 'bottomRight' ? `url(${require('../../img/logo.png')}` : 'none'}} 
            onClick={() => onLogoButton('bottomRight')}
          >
            {state.watermarkLocation !== 'bottomRight' && <div className="text"><p>Click to place Pinnies logo</p></div>}
          </button>
        </div>
      }
    </div>
  );
}

const ContestRules = ({show, onClose, contest, agreedToRules, tempAgreeValue, onAgree, onToggleAgreeValue}) => {
  const dispatch = useDispatch();

  if (!show) return null;
  return (
    <Modal onClose={() => onClose()}>
      <div className="rules-container">
        <h1 className="center-text">CONTEST RULES</h1>
        <p style={{ whiteSpace: 'pre-line'}}>{contest.rules}</p>
        {!agreedToRules && 
          <div className='flex-center' style={{marginBottom: 20}}>
            <input type="checkbox" value={tempAgreeValue} onChange={() => onToggleAgreeValue()} style={{marginRight: 10}}/>
            I AGREE
          </div>
        }
        {!agreedToRules && <div className='flex-center'><button className="btn large" style={{margin: '0 auto'}} onClick={() => onAgree()}>SUBMIT</button></div> }
      </div>
    </Modal>
  );
}

const PhotoRules = ({show, onClose, contest}) => {
  const photoRules = useRef(null);
  const location = useLocation();
  if (!show && location.pathname !== `/contests/${contest._id}/rules`) return null;

  return (
    <Modal ref={photoRules} onClose={() => onClose()}>
      <div className="rules-container">
        <PhotoRulesContent />
      </div>
    </Modal>
  );
}

const ContestEntry = ({contest}) => {
  const filePicker = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const entries = useSelector(state => state.entities.images.imagesList);

  const [state, setState] = useState({
    selectedImage: null,
    croppedImage: null,
    aspect: null,
    watermarkLocation: 'bottomRight',
    showContestRules: false,
    showPhotoRules: false,
    agreedToRules: !!localStorage.getItem(`${contest._id}-${user._id}-rules`),
    tempAgreeValue: false,
    loading: false,
  });

  useEffect(() => {
    dispatch(fetchContestEntries(contest._id));
  }, []);

  useEffect(() => {
    if (entries.length > 0) {
      setState({...state, aspect: entries[0].images.aspectRatio === '4:3' ? 4/3 : 3/4});
    }
  }, [entries]);

  const toggleContestRules = () => {
    setState({ ...state, showContestRules: !state.showContestRules});
  }

  const onImageSelect = (e) => {
    if (!e.target.files[0]) return;
    setState({ ...state, selectedImage: e.target.files[0]})
  }

  const cancelCrop = () => {
    setState({ ...state, selectedImage: null});
  }

  const onCropComplete = (croppedImage, aspectRatio) => {
    setState({ ...state, croppedImage, selectedImage: null, aspectRatio});
    window.scrollTo(0, this.preview.offsetTop - 60);
  }

  const toggleTempAgreeValue = () => {
    setState({ ...state, tempAgreeValue: !state.tempAgreeValue });
  }
  
  const togglePhotoRules = () => {
    setState({ ...state, showPhotoRules: !state.showPhotoRules});
  }

  const agreeToRules = async () => {
    if (!state.tempAgreeValue) {
      alert('You must agree to the contest rules');
      return;
    }
    localStorage.setItem(`${contest._id}-${user._id}-rules`, true);
    await dispatch(agreeToContestRules(contest));
    setState({ ...state, agreedToRules: true, showContestRules: false});
    //await this.contestRules.close();
    filePicker.current.click()
  };

  const updateWatermarkPosition = (position) => {
    setState({...state, watermarkLocation: position});
  }

  const onSubmit = async () => {
    setState({ ...state, loading: true})
    await dispatch(uploadContestEntry(state.croppedImage, state.watermarkLocation, contest));
    setState({
      ...state,
      selectedImage: null,
      croppedImage: null,
      aspect: null,
      watermarkLocation: 'bottomRight',
      loading: false,
    });
  };

  const isPassedDeadline = useMemo(() => new Date(contest.submissionEndDate) < new Date(), [contest]);
  const isApproved = useMemo(() => entries[0] && entries[0].isApproved, [entries]);
  const isRejected = useMemo(() => entries[0] && entries[0].isRejected, [entries]);
  const isPending = useMemo(() => entries[0] && !entries[0].isApproved && !entries[0].isRejected, [entries]);
  const image = useMemo(() => state.croppedImage ? state.croppedImage : 
                              entries[0] ? entries[0].images.find(i =>i.size == 'large').filename : null, 
                              [entries, state.croppedImage]);

  return (
    <div className='contest-entry-container'>
      <Loader fullscreen loading={state.loading} />
      <a className='contest-rules-btn' onClick={toggleContestRules}>CONTEST RULES</a>

      {isPassedDeadline && 
        <h3 className='deadline-text'>DEADLINE PASSED</h3>
      }
      {!isPassedDeadline && 
        <Countdown endDate={contest.submissionEndDate} />
      }
      
      <p className='contest-description'>{contest.description}</p>
      <h2 className='stylized-display-text'>PRIZES</h2>
      <Awards prizes={contest.prizes} />
      <p className='sub-text center-text'>Eligible Contest Winners will receive a Pinnies Pennant Invitation</p>

      {!isApproved && !isPending && !state.agreedToRules && !isPassedDeadline && 
        <button className="btn large rules" onClick={toggleContestRules}>ENTER CONTEST</button>
      }

      <a onClick={togglePhotoRules} style={{margin: '20px 0 20px 0'}}>PHOTO RULES</a>
      
      {!isApproved && !isPending && state.agreedToRules && !isPassedDeadline && 
        <>
          <label className="fileContainer">
            <button className="btn large" onClick={() => filePicker.current.click()}>SELECT PHOTO</button>
            <input type="file" accept='image/*;capture=camera' onChange={onImageSelect}/>
          </label>
        </>
      }


      {/* hidden file input so that the modal can programatically open the file picker */}
      <label className="fileContainer" style={{display: 'none'}}>
        <input accept='image/*;capture=camera' ref={filePicker} type="file" onChange={onImageSelect} />
      </label>

      {state.selectedImage && 
        <Cropper
          image={state.selectedImage}
          onComplete={onCropComplete}
          cancel={cancelCrop}
        />
      }

      <Status isApproved={isApproved} isRejected={isRejected} isPending={isPending} />
      {image && <ImagePreview image={image} isApproved={isApproved} isRejected={isRejected} isPending={isPending} onPositionChange={updateWatermarkPosition} />}

      {state.croppedImage && <button className="btn large submit-button" onClick={onSubmit}>SUBMIT</button> }

      <ContestRules show={state.showContestRules} contest={contest} onAgree={agreeToRules} agreedToRules={state.agreedToRules} tempAgreeValue={state.tempAgreeValue} onToggleAgreeValue={toggleTempAgreeValue} />
      <PhotoRules show={state.showPhotoRules && location.pathname !== `/contests/${contest._id}/rules`} contest={contest} onClose={togglePhotoRules} />
    </div>
  );



  // getBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = function () {
  //       resolve(reader.result);
  //     };
  //     reader.onerror = function (error) {
  //       reject('Error: ', error);
  //     };
  //   });
  // }
}

export default ContestEntry;