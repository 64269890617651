import { receiveImages } from './image_actions';
import { getContestRankings } from '../services/pinnies_api';

export const RECEIVE_SCORES = 'RECEIVE_SCORES';

export const receiveScores = (images) => ({
  type: RECEIVE_SCORES,
  scores: images
});



export const fetchScores = contest => async dispatch => {
  try {
    let images = await getContestRankings(contest._id);
    return dispatch(receiveScores(images));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};