import { RECEIVE_SCORES } from '../actions/scores_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_SCORES: {
      const newState = {...state, scoresList: action.scores};
      return newState;
    }

    default: return state;
  }
}