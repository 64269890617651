import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkout_form';
import Modal from './modal';
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_KEY);

const Paywall = () => {
  const [state, setState] = useState({
    showCheckout: false
  });


  return (
    <div className="paywall-container">
      <h1>For full access to live contests and galleries, you have to be a subscriber.</h1>
      <h1 className='pink-text'>Are you ready to swipe and vote through a WORLD OF GLAMOUR?</h1>
      <button className="btn large" onClick={() => setState({ ...state, showCheckout: true })}>SUBSCRIBE</button>

      {state.showCheckout &&
        <Modal onClose={() => this.setState({showCheckout: false})}>
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </Modal>
      }
    </div>
  );
  
};

export default Paywall;