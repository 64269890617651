import { receiveProfiles } from './profile_actions';
import { getInteractions, getLoggedOnUser, followProfile, unfollowProfile } from '../services/pinnies_api';

export const RECEIVE_INTERACTIONS = 'RECEIVE_INTERACTIONS';
export const RECEIVE_MY_INTERACTIONS = 'RECEIVE_MY_INTERACTIONS';
export const RECEIVE_INTERACTION = 'RECEIVE_INTERACTION';
export const REMOVE_INTERACTION = 'REMOVE_INTERACTION';

export const receiveInteractions = interactions => ({
  type: RECEIVE_INTERACTIONS,
  interactions,
});

export const receiveMyInteractions = interactions => ({
  type: RECEIVE_MY_INTERACTIONS,
  interactions,
});

export const receiveInteraction = interaction => ({
  type: RECEIVE_INTERACTION,
  interaction,
});

export const removeInteraction = interaction => ({
  type: REMOVE_INTERACTION,
  interaction,
});

export const fetchInteractions = (profileId) => async dispatch => {
  try {
    const interactions = await getInteractions(profileId);

    return dispatch(receiveInteractions(interactions));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};


export const fetchMyInteractions = () => async (dispatch, getState) => {
  try {
    const user = getState().user;
    const interactions = await getInteractions(user.profile._id);

    return dispatch(receiveMyInteractions(interactions));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};


export const follow = (toId) => async (dispatch, getState) => {
  try {
    // The API will set the fromProfile & toProfile properties

    const interaction = await followProfile(toId);

    dispatch(receiveInteraction(interaction));
    
    return dispatch(fetchMyInteractions());
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const unfollow = (interaction) => async dispatch => {
  try {
    await unfollowProfile(interaction.toProfile, interaction);

    return dispatch(removeInteraction(interaction));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};