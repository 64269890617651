import React from 'react';

export default props => {
  const color = props.color || 'white';

  return (
    <svg x="0px" y="0px" viewBox="0 0 100 100" style={{ width: 35, height: 35 }} fill={color}>
      <path d="M72.50469,22.82091C68.34429,14.7625,60.77261,10.67659,50,10.67659S31.65571,14.7625,27.49531,22.82091C24.34147,28.9297,23.839,35.9818,23.78738,42.711q-.0063.12376-.00635.24923V76.22968c0,.02434.00332.0479.00366.07214.00132.08564.00934.17142.013.2572.005.07385.00884.14781.01715.22073.01384.20823.02068.41611.04854.62482a9.23042,9.23042,0,0,0,4.3061,6.66117C33.81726,87.5545,41.1621,89.32341,50,89.32341s16.18277-1.76891,21.83049-5.25767a9.23042,9.23042,0,0,0,4.3061-6.66117c.02786-.20871.0347-.41659.04854-.62482.00831-.07292.01212-.14688.01715-.22073.00367-.08578.01169-.17156.013-.2572.00034-.02424.00366-.0478.00366-.07214V42.96024q0-.12537-.00635-.24913C76.161,35.982,75.65853,28.92965,72.50469,22.82091ZM71.40927,42.96024H71.414V67.64162a31.50994,31.50994,0,0,0-11.693-6.17019,18.15585,18.15585,0,0,0,8.43678-15.32465c0-1.766,0-3.96385-.19986-6.26784l-.3227-3.72093-3.68554-.60531c-8.92845-1.46646-16.59363-3.92593-22.78254-7.31018l-4.25908-2.329-2.28537,4.28264c-2.78045,5.21041-2.78045,11.418-2.78045,15.95059A18.15585,18.15585,0,0,0,40.279,61.47143a31.50883,31.50883,0,0,0-11.693,6.17019V42.96024h.00474C28.66077,31.7717,29.84857,15.48155,50,15.48155S71.33923,31.7717,71.40927,42.96024ZM36.64718,46.14678c0-4.04856.00528-9.54823,2.21464-13.68844,5.15518,2.819,13.01382,5.98063,24.30912,7.83577.17963,2.071.18188,4.09866.18188,5.85267a13.35282,13.35282,0,1,1-26.70564,0ZM30.69471,79.97783a4.49341,4.49341,0,0,1-.90563-6.86861,26.67734,26.67734,0,0,1,15.11707-7.76631,1.92652,1.92652,0,0,0,.21956.65668C45.78948,67.08005,48.73192,67.403,50,67.403s4.2105-.32294,4.87427-1.4034a1.92652,1.92652,0,0,0,.21956-.65668,26.67718,26.67718,0,0,1,15.11707,7.76631,4.49341,4.49341,0,0,1-.90563,6.86861C64.75729,82.78727,58.53,84.51845,50,84.51845S35.24276,82.78727,30.69471,79.97783Z" />
    </svg>
  );
}
