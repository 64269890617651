import React from 'react';

export default props => {
  const color = props.color || 'white';

  return (
    <svg x="0px" y="0px" viewBox="0 0 100 100" style={{ width: 30, height: 30 }}>
      <g transform="translate(0,-952.36218)">
        <path d="M 7.6875,962.36625 A 3.0003,3.0001473 0 0 0 5,965.3661 l 0,9.99949 a 3.0003,3.0001473 0 0 0 3,2.99985 l 1,0 0,60.99686 a 3.0003,3.0001473 0 0 0 3,2.9999 l 18,0 a 3.0003,3.0001473 0 0 0 3,-2.9999 l 0,-29.3735 c 6.00875,-0.8198 11.112034,-3.9097 14.40625,-9.1558 1.003671,-1.59833 1.869153,-3.35546 2.59375,-5.28094 0.724597,1.92548 1.590079,3.68261 2.59375,5.28094 3.294216,5.2461 8.3975,8.336 14.40625,9.1558 l 0,29.3735 a 3.0003,3.0001473 0 0 0 3,2.9999 l 18,0 a 3.0003,3.0001473 0 0 0 3,-2.9999 l 0,-60.99686 1,0 a 3.0003,3.0001473 0 0 0 3,-2.99985 l 0,-9.99949 a 3.0003,3.0001473 0 0 0 -3,-2.99985 l -84,0 a 3.0003,3.0001473 0 0 0 -0.3125,0 z m 3.3125,5.9997 78,0 0,3.99979 -1,0 -76.3125,0 -0.6875,0 0,-3.99979 z m 4,9.99949 18.9375,0 c -0.385905,8.13041 -3.105346,12.94344 -5.96875,15.90544 -1.545031,1.59823 -3.166448,2.64608 -4.5,3.28108 -1.333552,0.635 -2.552088,0.81246 -2.46875,0.81246 a 3.0003,3.0001473 0 0 0 -3,2.99988 l 0,34.9982 -3,0 0,-57.99706 z m 24.96875,0 6.90625,0 c -0.325876,8.58151 -1.928456,15.13528 -4.53125,19.28027 -2.910477,4.63499 -6.689906,6.71839 -12.34375,6.71839 a 3.0003,3.0001473 0 0 0 -3,2.9999 l 0,28.9985 -3,0 0,-32.5921 c 0.655554,-0.219 1.307915,-0.4532 2.0625,-0.8125 1.947698,-0.9274 4.138781,-2.3794 6.21875,-4.53098 3.895628,-4.02976 7.275182,-10.50723 7.6875,-20.06148 z m 13.15625,0 6.90625,0 c 0.412318,9.55425 3.791872,16.03172 7.6875,20.06148 2.079969,2.15158 4.271052,3.60358 6.21875,4.53098 0.754585,0.3593 1.406946,0.5935 2.0625,0.8125 l 0,32.5921 -3,0 0,-28.9985 a 3.0003,3.0001473 0 0 0 -3,-2.9999 c -5.653844,0 -9.433273,-2.0834 -12.34375,-6.71839 -2.602794,-4.14499 -4.205374,-10.69876 -4.53125,-19.28027 z m 12.9375,0 18.9375,0 0,57.99706 -3,0 0,-34.9982 a 3.0003,3.0001473 0 0 0 -3,-2.99988 c 0.08334,0 -1.135198,-0.17746 -2.46875,-0.81246 -1.333552,-0.635 -2.954969,-1.68285 -4.5,-3.28108 -2.863404,-2.962 -5.582845,-7.77503 -5.96875,-15.90544 z" fill={color}/>
      </g>
    </svg>
  );
}
