import { RECEIVE_SEARCH_RESULTS } from '../actions/search_actions';

export default (state = [], action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_SEARCH_RESULTS: {
      const newState = action.usernames;
      return newState;
    }

    default: return state;
  }
}