import { RECEIVE_PROFILES, RECEIVE_PROFILE, RECEIVE_MY_PROFILE } from '../actions/profile_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PROFILES: {
      return { 
        ...state,
        profilesList: action.profiles
      };
    }
    case RECEIVE_PROFILE: {
      return { 
        ...state, 
        profilesList: {...state.profiles, [action.profile.username]: action.profile },
        currentProfile: action.profile
      };
    }
    case RECEIVE_MY_PROFILE: {
      return {
        ...state,
        profile: action.profile
      }
    }

    default:
      return state;
  }
}