import jwtDecode from 'jwt-decode';
const BASE_URL = process.env.REACT_APP_PINNIES_API_URL.replace(/\/$/, '');

let onUnauthorizedCallback = (response) => {
    console.info('THe Pinnies API token is invalid');
};

export const onUnauthorized = (callback) => {
    onUnauthorizedCallback = callback;
};

export const loginUser = async (email, password) => {
    const token = await _fetch('auth/login', 'POST', {email, password});
    if (token && !token.error) {
        localStorage.setItem('pinnies_api_token', JSON.stringify(token));
    }
    
    return token;
};

export const logoutUser = () => {
    localStorage.removeItem('pinnies_api_token');
    
    return true;
};

export const registerUser = async (user) => {
    const token = await _fetch('auth/register', 'POST', user);
    localStorage.setItem('pinnies_api_token', JSON.stringify(token));
    return token;
};

export const verifyEmail = async (email, token) => {
    const response = await _fetch('auth/email', 'GET', {email, token});
    return response;
};

export const sendEmailVerificationRequest = async () => {
    const response = await _fetch('auth/email', 'POST');
    return response;
};

export const resetPassword = async (email, token, password) => {
    const response = await _fetch('auth/password', 'POST', {email, token, password});
    return response;
};

export const sendPasswordResetRequest = async (email) => {
    const response = await _fetch('auth/password', 'GET', {email});
    return response;
};

export const deleteUser = async () => {
    const response = await _fetch('users/me', 'DELETE');
};

export const getLocalUser = () => {
    // No need to make request to API. The JSON Web Token holds the user object
    const token = JSON.parse(localStorage.getItem('pinnies_api_token'));

    if (token == null)
        return null;
        
    return jwtDecode(token.token);
};

export const getLoggedOnUser = async () => {
    return await _fetch('users/me');
}

export const getUserProfile = async (userId) => {
    return await _fetch(`users/${userId}/profile`);
};

export const getProfile = async (profileId) => {
    return await _fetch(`profiles/${profileId}`);
};

export const updateProfile = async (profileId, profile) => {
    return await _fetch(`profiles/${profileId}`, 'POST', profile);
};

export const searchProfiles = async (username) => {
    return await _fetch(`profiles/search/${username}`);
};

export const getInteractions = async (profileId) => {
    return await _fetch(`profiles/${profileId}/interactions`);
};

export const followProfile = async (profileId) => {
    return await _fetch(`profiles/${profileId}/interactions`, 'POST', { interactionType: 'FOLLOW' });
};

export const unfollowProfile = async (profileId, interaction) => {
    return await _fetch(`profiles/${profileId}/interactions/${interaction._id}`, 'DELETE');
};

export const getContests = async (isContestant = false) => {
    const status = isContestant ? 'all' : 'live,closed';
    return await _fetch(`contests/lookup/status?status=${status}`);
};

export const getContest = async (contestId) => {
    return await _fetch(`contests/${contestId}`);
};

export const getContestImages = async (contestId) => {
    return await _fetch(`contests/${contestId}/images`);
};

export const getMyContestImages = async (contestId) => {
    return await _fetch(`contests/${contestId}/entries`);
};

export const getContestRankings = async (contestId) => {
    return await _fetch(`contests/${contestId}/rankings`);
};

export const getProfileImages = async (profileId) => {
    return await _fetch(`profiles/${profileId}/images`);
};

export const uploadContestImage = async (contestId, data, metadata = {}) => {
    return await _fetch(`contests/${contestId}/images`, 'POST', {
        data,
        ...metadata
    });
}

export const voteForImage = async (contestId, imageId) => {
    return await _fetch(`contests/${contestId}/images/${imageId}/vote`, 'POST');
};

export const getContestImageUrl = async (filename) => {
    return await _fetch(`contestimages/url?filename=${filename}`);
};

export const getProfileImageUrl = async (filename) => {
    return await _fetch(`profiles/avatars/url?filename=${filename}`);
};

export const getLeaderboard = async (idol) => {
    return await _fetch(`leaderboard/${idol}`);
};

export const createSubscription = async (token, type) => {
    // TODO: Implement stripe subscription
    return await _fetch('subscriptions', 'POST', {
        token,
        type
    });
};

export const requestStripeSubscription = async () => {
    return await _fetch('subscriptions/stripe', 'POST');
};

export const getAuditions = async () => {
    return await _fetch('profiles/me/applications');
};

export const createAudition = async (audition) => {
    return await _fetch('applications', 'POST', audition);
};

export const createAgreement = async (agreement) => {
    return await _fetch('agreements', 'POST', agreement);
};

export const getIpInfo = async () => {
    return await _fetch('ipinfo');
};

const _fetch = (path, method = 'GET', body = null) => {

    const token = JSON.parse(localStorage.getItem('pinnies_api_token'));
    const request = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token != null)
        request.headers.Authorization = `Bearer ${token.token}`;
    
    if (body != null && method == 'GET')
        path += '?' + Object.keys(body).reduce((kvPairs, key) => [...kvPairs, `${key}=${encodeURIComponent(body[key])}`], []).join('&')
    else if (body != null)
        request.body = JSON.stringify(body);

    return fetch(`${BASE_URL}/api/${path}`, request)
        .then(response => {
            if (response.status == 401) {
                return onUnauthorizedCallback(response);
            }
            return response.json();
        })
        .then(json => {
            if (!json.error)
                return json;
            else
                throw new Error(json.error);
        });
};