import { connect } from 'react-redux';
import Audition from './audition';
import { submitAudition, fetchAuditions, becomeVoter } from '../../actions/audition_actions';
import { logout, deleteAccount } from '../../actions/user_actions';

const msp = state => {
  const auditions = Object.values(state.entities.auditions);
  const isPending = auditions.reduce((acc, audition) => acc || !audition.isRejected && !audition.isApproved, false);
  const isRejected = auditions.length > 0 && auditions.every(a => a.isRejected);
  const canReaudition = isRejected && state.user.canAudition;

  return {
    isPending,
    isRejected,
    canReaudition,
  };
};
const mdp = dispatch => ({
  fetchAuditions: () => dispatch(fetchAuditions()),
  submitAudition: images => dispatch(submitAudition(images)),
  becomeVoter: () => dispatch(becomeVoter()),
  logout: () => dispatch(logout()),
  deleteAccount: () => dispatch(deleteAccount()),
});

export default connect(msp, mdp)(Audition);