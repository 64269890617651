import React, { Component } from 'react';
import '../../css/winner.css';

export default class Winner extends Component {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);
  }

  render() {
    const { award } = this.props;
    let winnerText, winnerIcon, instructionsText; 

    if (award.awardType === 'DIAMOND') {
      winnerText = 'You are the Contest WINNER!';
      winnerIcon = require('../../img/awards/diamond.png')
      instructionsText = 'Please check your email to verify eligibility to claim prize money & compete in The Pinnies Pennant for Grand Prize Money & The Pinnies Diamond Pendant';
    }
    if (award.awardType === 'PLATINUM') {
      winnerText = 'You are the Contest\'s First Runner Up!';
      winnerIcon = require('../../img/awards/platinum.png')
      instructionsText = 'Please check your email to verify eligibility to claim prize money & compete in The Pinnies Pennant for Grand Prize Money & The Pinnies Diamond Pendant';
    }
    if (award.awardType === 'GOLD') {
      winnerText = 'You are the Contest\'s Second Runner Up!';
      winnerIcon = require('../../img/awards/gold.png')
      instructionsText = 'Please check your email to verify eligibility to claim prize money & compete in The Pinnies Pennant for Grand Prize Money & The Pinnies Diamond Pendant';
    }
    if (award.awardType === 'SILVER') {
      winnerText = 'You are a Contest Wild Card!';
      winnerIcon = require('../../img/awards/silver.png')
      instructionsText = 'Please check your email to verify eligibility to compete in The Pinnies Pennant for Grand Prize Money & The Pinnies Diamond Pendant';
    }
    if (award.awardType === 'PINK') {
      winnerText = 'You are in the hunt...';
      winnerIcon = require('../../img/awards/pink.png')
      instructionsText = 'Please Continue to Enter Contests for a Chance to Win & Gain Access to The Pinnies Pennant for Grand Prize Money & The Pinnies Diamond Pendant';
    }

    return (
      <div className='winner-container'>
        <h1 className='stylized-display-text'>CONGRATULATIONS!</h1>
        <div className="row">
          <img src={winnerIcon} alt=""/>
          <h2>{award.awardType}</h2>
        </div>
        <h2>{winnerText}</h2>
        <p>{instructionsText}</p>
        <p>Thank You!</p>
        <p style={{opacity: 0.6}}>(Please be sure to check your spam folder as well) </p>
        <div><button className="btn large" onClick={this.dismiss}>DISMISS</button></div>
      </div>
    );
  }

  async dismiss() {
    await this.props.onClose();
    this.props.setAwardAsViewed(this.props.award);
  }
}