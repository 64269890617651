import { receiveImage, receiveProfileImage } from './image_actions';
import { getContests, getContest, uploadContestImage, voteForImage, createAgreement } from '../services/pinnies_api';

export const RECEIVE_CONTESTS = 'RECEIVE_CONTESTS';
export const RECEIVE_CONTEST = 'RECEIVE_CONTEST';

export const receiveContests = contests => ({
  type: RECEIVE_CONTESTS,
  contests,
});

export const receiveContest = contest => ({
  type: RECEIVE_CONTEST,
  contest,
});


export const fetchContests = isContestant => async dispatch => {
  try {
    const contests = await getContests(isContestant);

    return dispatch(receiveContests(contests));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};


export const fetchContest = contestId => async dispatch => {
  try {
    const contest = await getContest(contestId);

    return dispatch(receiveContest(contest));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const vote = (image) => async (dispatch, getState) => {
  try {
    const contestImage = await voteForImage(image.contest._id, image._id);

    dispatch(receiveImage(contestImage));
  } catch (err) {
    if (err == 'ParseError: 141 You already voted for this image') {
      alert('You already voted for this Pinnie');
      return;
    }
    console.log(err);
    alert(err);
  }
};

export const uploadContestEntry = (data, position, contest) => async dispatch => {  
  try {
    // const images = [];// await Parse.Cloud.run('uploadContestImage', {data, position});
    // const contestPointer = { className: 'Contest', objectId: contest.objectId, __type: 'Pointer' }
    // let entry = {};//await Parse.Cloud.run('submitContestEntry', { images, contest: contestPointer});
    const entry = await uploadContestImage(contest._id, data, { watermarkPosition: position });
    const profile = JSON.parse(localStorage.getItem('profile'));

    dispatch(receiveImage(entry));
    dispatch(receiveProfileImage(profile.username, entry._id));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const agreeToContestRules = contest => async dispatch => {  
  try {
    const agreement = { //new Parse.Object('AgreementRecord', 
      agreementType: 'CONTEST_ENTRY',
      contest: contest._id,//new Parse.Object('Contest', {id: contest.objectId}),
      deviceType: window.navigator.userAgent,
      localeIdentifier: window.navigator.language,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return await createAgreement(agreement);
  } catch (err) {
    console.log(err);
    alert(err);
  }
};
