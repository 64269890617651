import React, { Component } from 'react';
import '../../css/nav.css';
import ContestsIcon from '../../img/buttons/contests';
import LeaderboardIcon from '../../img/buttons/leaderboard';
import SearchIcon from '../../img/buttons/search';
import ProfileIcon from '../../img/buttons/profile';
import Modal from '../shared/modal';
import Account from '../misc/account';

export default class Contests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAccount: this.props.showAccount,
    };

    this.goToSearch = this.goToSearch.bind(this);
    this.goToContests = this.goToContests.bind(this);
    this.goToProfile = this.goToProfile.bind(this);
    this.goToLeaderboard = this.goToLeaderboard.bind(this);
    this.closeAccount = this.closeAccount.bind(this);
  }

  render() {
    const { isMobile, location, screenWidth } = this.props;

    return (
      <>
        <div className='nav-spacer' />
        <div className='nav-container' style={{ left: location.pathname.includes('/contests') && !isMobile ? 220 : 0 }}>
          {screenWidth > 900 ? this.LargeLayout() : this.SmallLayout()}
        </div>

        {this.state.showAccount &&
          <Modal ref={v => this.accountModal = v} onClose={() => this.setState({ showAccount: false })}>
            <Account close={this.closeAccount} showFollowing={true} />
          </Modal>
        }
      </>
    );
  }

  LargeLayout() {
    const { location, username } = this.props;
    
    return (
      <>
        <div className="left">
        </div>
        <div className="center">
          <img src={require('../../img/logo-with-text.png')} alt="" />
        </div>
        <div className="right">
          <button onClick={this.goToContests}>
            <ContestsIcon color={location.pathname.includes('/contests') ? '#ee2b7b' : 'white'} />
          </button>
          <button onClick={this.goToLeaderboard}>
            <LeaderboardIcon color={location.pathname === '/leaderboard' ? '#ee2b7b' : 'white'} />
          </button>
          <button onClick={this.goToProfile}>
            <ProfileIcon color={location.pathname === ('/user/' + username) ? '#ee2b7b' : 'white'} />
          </button>
          <button onClick={this.goToSearch}>
            <SearchIcon color={location.pathname === '/search' ? '#ee2b7b' : 'white'} />
          </button>
        </div>
      </>
    );
  }

  SmallLayout() {
    const { location, username } = this.props;
    
    return (
      <>
        <div className="left">
          <button onClick={this.goToContests}>
            <ContestsIcon color={location.pathname.includes('/contests') ? '#ee2b7b' : 'white'} />
          </button>
          <button onClick={this.goToLeaderboard}>
            <LeaderboardIcon color={location.pathname === '/leaderboard' ? '#ee2b7b' : 'white'} />
          </button>
        </div>
        <div className="center">
          <img src={require('../../img/logo-with-text.png')} alt="" />
        </div>
        <div className="right">
          <button onClick={this.goToProfile}>
            <ProfileIcon color={location.pathname === ('/user/' + username) ? '#ee2b7b' : 'white'} />
          </button>
          <button onClick={this.goToSearch}>
            <SearchIcon color={location.pathname === '/search' ? '#ee2b7b' : 'white'} />
          </button>
        </div>
      </>
    );
  }

  goToContests() {
    this.props.history.push('/contests')
  }

  goToLeaderboard() {
    this.props.history.push('/leaderboard')
  }

  goToSearch() {
    this.props.history.push('/search')
  }

  goToProfile() {
    if (this.props.userType === 'CONTESTANT')
      this.props.history.push('/user/' + this.props.username)
    else
      this.setState({showAccount: true});
  }

  closeAccount() {
    this.accountModal.close();
  }
}