import React, { useState } from 'react';
import { subscribe } from '../../actions/user_actions';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Loader from './loader';
import { useDispatch } from 'react-redux';

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
 
  const [state, setState] = useState({
    loading: false,
  });
  
  const style = {
    base: {
      color: 'white',
      fontFamily: '"kokila',
      fontSmoothing: 'antialiased',
      fontSize: '24px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setState({...state, loading: true});

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    // const {clientSecret} = await requestStripeSubscription();

    // const result = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: cardElement
    //   },
    //   setup_future_usage: 'off_session'
    // });
    const result = await stripe.createToken(cardElement);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setState({...state, loading: false});
    } else {
      console.log(result);
      dispatch(subscribe(result.token));
    }
  };

  return (
    <form className='checkout-form' onSubmit={handleSubmit}>
      <img src={require('../../img/logo-with-text.png')} alt="" />
      <h1>($1.99)/month</h1>
      <CardElement style={style} />
      <p>Safe and secure payments processed by 
        <a href="https://stripe.com" target='blank'>
          <img className="stripe-logo" src={require('../../img/stripe-logo.png')} alt="" />
        </a>
      </p>
      <button type="submit" className='btn large'>SUBMIT</button>
      <p className="thanks-text">Thank you for your support!</p>
      <Loader loading={state.loading} fullscreen={true} />
    </form>
  );

}

export default CheckoutForm;