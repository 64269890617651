import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberedList from '../shared/numbered_list';
import Cropper from '../shared/cropper';
import Loader from '../shared/loader';
import loadImage from 'blueimp-load-image';
import '../../css/audition.css';
import { becomeVoter, fetchAuditions, submitAudition } from '../../actions/audition_actions';
import { deleteAccount, logout } from '../../actions/user_actions';

const Pending = () => {
  return (
    <div className='pending-audition-container'>
      <h1>PENDING PINNIES AUDITION</h1>
      <p>Your submission has been received and is currently under review.</p>
      <p>The Pinnies Gatekeepers will verify your entry in the order in which it was received.</p>
      <p>Please be patient.</p>
      <p style={{fontSize: 30, marginTop: 40}}>Thank you and good luck!</p>
    </div>
  );
};

const Rejected = ({onReaudition, onCancelAudition}) => {
  return (
    <div className='rejected-audition-container'>
      <h1>DENIED PINNIES AUDITION</h1>
      <p>Your audition was not approved. You have the option to audition again. Would you like to do so?</p>
      <div>
        <button className="btn large" onClick={() => onReaudition()}>YES</button>
        <button className="btn large" onClick={() => onCancelAudition()}>NO</button>
      </div>
    </div>
  );
}

const BecomeVoter = ({ canReaudition, onBecomeVoter, onCancelVoter }) => {
  let text = !canReaudition ? 'Sorry, your audition was not approved.' : '';
  text += ' Would you like to become a Pinnies Voter?'

  return (
    <div className='rejected-audition-container'>
      <h1>BECOME A PINNIES VOTER</h1>
      <p>{text}</p>
      <div>
        <button className="btn large" onClick={() => onBecomeVoter()}>YES</button>
        <button className="btn large" onClick={() => onCancelVoter()}>NO</button>
      </div>
    </div>
  );
}

const AuditionForm = ({images, onSelectImage, onSubmitAudition}) => {

  return (
    <div className='audition-image-container'>
      <h1>PINNIES AUDITION</h1>

      <p>Clean Mirrors &amp; Clean Backgrounds Set a Good Impression</p>
      <p>Less Distractions = More Focus on You</p>
      <p>Have Fun &amp; Take Some Selfies!</p>

      <img src={images[0]} alt="" className="portrait-selfie"/>
      <label className="fileContainer">
        <button className="btn">SELECT PORTRAIT SELFIE</button>
        <input type="file" accept='image/*;capture=camera' onChange={(e) => onSelectImage(e, 0)} />
      </label>

      <img src={images[1]} alt="" className="portrait-selfie"/>
      <label className="fileContainer">
        <button className="btn">SELECT FULL LENGTH MIRROR SELFIE</button>
        <input type="file" accept='image/*;capture=camera' onChange={(e) => onSelectImage(e, 1)} />
      </label>

      <img src={images[2]} alt="" className="freestyle-selfie"/>
      <label className="fileContainer">
        <button className="btn">SELECT FREESTYLE SELFIE</button>
        <input type="file" accept='image/*;capture=camera' onChange={(e) => onSelectImage(e, 2)} />
      </label>

      <button className="btn large submit" onClick={() => onSubmitAudition()}>SUBMIT!</button>
      {/* <button className="btn-underline" onClick={this.props.logout}>LOGOUT</button> */}
    </div>
  );
}

const Eligibility = ({onAgree, onLogout}) => {
  return (
    <div className="eligibility-container">
      <h1>Please Read the Eligibility Requirements:</h1>
      <p style={{ color: '#D94A3B'}}>VOID WHERE PROHIBITED BY LAW</p>
      <p>Applicants must be located in and a US Citizen or a Legal Resident within the 50 United States or the District of Columbia in order to claim a prize.</p>
      <NumberedList>
        <p>You MUST be at least 18 years of age at the time of application and under 33 years of age to compete in any contest. <br />Alabama &amp; Nebraska residents must be over 19 years of age at the time of application. <br />Mississippi residents must be over 21 years of age at the time of application.</p>
        <p>You MUST be a female.</p>
        <p>You MUST be in good health &amp; in good physical condition.</p>
        <p>You MUST submit 3 photos of yourself.</p>
        <p>You MUST agree to the rule that all applicants are subject to approval by The Pinnies Gatekeepers based upon their evaluation of the applicant's photogenic qualities as well as the quality of submitted photos in order to determine eligibility audition.</p>
        <p>Photos MUST be in JPG, JPEG, or PNG file format.</p>
      </NumberedList>
      <p>AUDITIONER ASSUMES ALL LIABILITY FOR LOSSES OR INJURIES CAUSED OR CLAIMED TO BE CAUSED BY PARTICIPATING IN THIS AUDITION.</p>
      <button className="btn large" onClick={() => {
        onAgree();
        window.scrollTo(0, 0);
      }}>I AGREE</button>
      <button className="btn-underline" onClick={onLogout}>LOGOUT</button>
    </div>
  );
}

const AuditionRequirements = ({onAgree}) => {
  return (
    <div className="audition-requirements-container">
      <h1>Now let's get 3 current snapshots of you. Please follow these requirements:</h1>
      <NumberedList>
        <p>Photos MUST be in high resolution &amp; in good lighting conditions.</p>
        <p>Photos MUST be taken in form-fitting attire. <br />This allows for clarity and sound decision.</p>
        <p>All THREE photos must be within the same time frame &amp; same attire. This helps eliminate fake auditions with stolen photos. <span style={{ color: 'red' }}>For Safety Reasons: Your audition will be denied if you do not follow this requirement.</span></p>
        <p>The FIRST photo must be a selfie portrait taken by you. Be sure to smile, you're auditioning after all!</p>
        <p>The SECOND photo must be a full length selfie taken by you from head to toe. You need a mirror for this one.</p>
        <p>The THIRD photo must be a freestyle selfie taken by you.  Show your creativity &amp; expression as if you are taking it for a Pinnies Contest!</p>
        <p>Please do not include other people or pets in your audition photos.</p>
      </NumberedList>
      <button className="btn large" onClick={() => {
        onAgree();
        window.scrollTo(0, 0);
      }}>I AGREE</button>
      {/* <button className="btn-underline" onClick={this.props.logout}>LOGOUT</button> */}
    </div>
  );
}
const Audition = () => {
  const AUDITION_IMAGE_COUNT = 3;
  const dispatch = useDispatch();
  
  const auditions = useSelector(state => state.entities.auditions.auditionsList);
  const user = useSelector(state => state.user);
  const [state, setState] = useState({
    agreedToEligibility: false,
    agreedToRequirements: false,
    indexToBeCropped: null,
    imageToBeCropped: null,
    images: Array(AUDITION_IMAGE_COUNT).fill(''),
    isReauditioning: false,
    showVoterOption: false,
    initialLoad: true,
    submitting: false,
  });

  useEffect(() => {
    dispatch(fetchAuditions());
    setTimeout(() => setState({...state, initialLoad: false }), 500);
  }, []);
  
  useEffect(() => {
    if (state.submitting || state.isReauditioning)
      setState({ ...state, submitting: false, isReauditioning: false });
  }, [auditions]);
  
  const onImageSelect = async (e, index) => {
    console.log(e.currentTarget);
    const image = e.currentTarget.files[0];
    if (!image) return;

    if (index === 2) {
      // required for correct orientation
      loadImage(
        image,
        (img) => {
          const images = [...state.images]
          const data = img.toDataURL('image/jpeg');
          images[index] = data;
          setState({...state, images })
        },
        { orientation: true,
        canvas: true }
      );
    }
    else {
      const data = await getBase64(image);
      setState({ ...state, imageToBeCropped: data, indexToBeCropped: index });
    }
  }

  const logoutUser = () => {
    dispatch(logout());
  }

  const cancelCrop = () => {
    setState({ ...state, imageToBeCropped: null });
  }

  const onCropComplete = (croppedImage) => {
    const images = [...state.images]
    images[state.indexToBeCropped] = croppedImage;

    setState({ ...state, images, imageToBeCropped: null, indexToBeCropped: null });
  };

  const submit = async () => {
    const { images } = state;

    if (!images[0] || !images[1] || !images[2]) {
      alert('Please upload all three images.');
      return;
    }

    setState({ ...state, submitting: true});
    dispatch(submitAudition(images));
  };

  const reaudition = () => {
    setState({ ...state, isReauditioning: true});
    window.scrollTo(0, 0);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject('Error: ', error);
      };
    });
  };
  
  const isPending = useMemo(() => !!auditions ? auditions.reduce((acc, audition) => acc || !audition.isRejected && !audition.isApproved, false) : false, [auditions]);
  const isRejected = useMemo(() => !!auditions ? auditions.length > 0 && auditions.every(a => a.isRejected) : false, [auditions]);
  const canReaudition = useMemo(() => isRejected && user.canAudition, [isRejected, user]);

  return (state.initialLoad ? <Loader loading fullscreen/> :
    <div className="audition-container">
      <img className='logo' src={require('../../img/logo-with-text.png')} alt="" />

      {!state.agreedToEligibility && !state.initialLoad && !isPending && (!isRejected || state.isReauditioning) &&
        <Eligibility onAgree={() => setState({...state, agreedToEligibility: true})} onLogout={logoutUser} />
      }
      {state.agreedToEligibility && !state.agreedToRequirements && !state.initialLoad && 
        <AuditionRequirements onAgree={setState({...state, agreedToRequirements: true})} />
      }
      {(state.agreedToEligibility && state.agreedToRequirements && !isPending && !isRejected && !state.initialLoad || state.isReauditioning && state.agreedToEligibility && state.agreedToRequirements) &&
        <AuditionForm images={state.images} onSelectImage={(evt, index) => onImageSelect(evt, index)} onSubmitAudition={() => submit()} />
      }
      {isPending &&
        <Pending />
      }
      {isRejected && !state.isReauditioning && canReaudition && !state.showVoterOption &&
        <Rejected onReaudition={() => reaudition()} onCancelAudition={() => setState({...state, showVoterOption: true})} />
      }
      {(isRejected && !canReaudition || state.showVoterOption) &&
        <BecomeVoter canReaudition={canReaudition} onBecomeVoter={() => dispatch(becomeVoter())} onCancelVoter={() => dispatch(deleteAccount())} />
      }

      {state.imageToBeCropped &&
        <Cropper
          image={state.imageToBeCropped}
          onComplete={onCropComplete}
          cancel={cancelCrop}
          aspect={3/4}
          lockAspect={true}
        />
      }

      <Loader loading={state.submitting || state.initialLoad} fullscreen={true} />
    </div>
  );
}

export default Audition;