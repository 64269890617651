import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { fetchContest } from '../../actions/contest_actions';
import { clearContestImages, fetchContestImages } from '../../actions/image_actions';
import ContestEntry from '../contests/contest_entry_container';
import Scores from '../contests/scores';
import Gallery from '../shared/gallery';
import Modal from '../shared/modal';

const ContestRules = ({show, contest, onClose}) => {
  if (!show) return null;

  
  return (
    <Modal onClose={() => onClose()}>
      <div className="rules-container">
        <h1 className="center-text">CONTEST RULES</h1>
        <p style={{ whiteSpace: 'pre-line' }}>{contest.rules}</p>
      </div>
    </Modal>
  );
}

const ContestDetails = ({contestId}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const profile = useSelector(state => state.entities.profiles.profile);
  const contest = useSelector(state => state.entities.contests.currentContest);
  const imageIds = useSelector(state => state.entities.contestImageIds[contestId]);
  const images = useSelector(state => state.entities.images.imagesList);
  const [state, setState] = useState({
    showContestRules: false,
    isOpenSubmission: false,
    isLiveSubmission: false,
    leaderboardText: '',
    isShowingScores: false
  });

  useEffect(() => {
    if (contestId) {
      console.log(contestId);
      dispatch(fetchContest(contestId));
    }
  }, [contestId]);

  useEffect(() => {
    dispatch(clearContestImages());
    if (contest) {
      console.log('fetching contest images: ' + contestId);
      dispatch(fetchContestImages(contestId));
    }
  }, [contest]);

  useEffect(() => {
    if (contest != null) {
      setState({
        isOpenSubmission: contest.status == 'open',
        isLiveSubmission: contest.status === 'live',
        leaderboardText: contest.status === 'live' ? 'LIVE SCORES' : 'FINAL SCORES',
        isShowingScores: location.pathname === `/contests/${contest._id}/scores`
      });
    }
  }, [contest]);

  const toggleContestRules = () => {
    setState({...state, showContestRules: !state.showContestRules });
  }

  const goToScores = () => {
    history.push(location.pathname + '/scores');
    setState({...state, isShowingScores: true});
  }

  return (
    <div className='contest-details-container'>
      {contest && 
      <>
        <section className='contest-info'>
          <h1>{contest.name}</h1>
          <h3>{contest.subtitle}</h3>
          {!state.isLiveSubmission &&
            <button className="btn large" onClick={goToScores}>{state.leaderboardText}</button>
          }
        </section>

        {profile && profile.userType === 'CONTESTANT' &&
          <a className='contest-rules-btn' onClick={toggleContestRules}>CONTEST RULES</a>
        }
        {state.isLiveSubmission && <ContestEntry contest={contest} /> }
        {!state.isLiveSubmission && <Gallery images={images} /> }

        {state.isShowingScores && 
          <Modal onClose={() => {history.goBack(); setState({...state, isShowingScores: false})}}>
            <Scores contest={contest} />
          </Modal>
        }

        <ContestRules contest={contest} show={state.showContestRules} onClose={toggleContestRules} />
      </>
      }
    </div>
  );
}

export default ContestDetails;