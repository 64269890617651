import { RECEIVE_AUDITIONS, RECEIVE_AUDITION } from '../actions/audition_actions';

export default (state = [], action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_AUDITIONS: {
      const newState = { ...state, auditionsList: action.auditions };
      return newState;
    }
    case RECEIVE_AUDITION: {
      const newState = { ...state, currentAudition: action.audition };
      return newState;
    }

    default: return state;
  }
}