import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { vote } from '../../actions/contest_actions';
import { getContestImageFileUrl } from '../../actions/image_actions';

const getUrl = async (image, screenWidth, dispatch) => {
  const filename = screenWidth > 1000 ? image.images.find(i =>i.size == 'large').filename : image.images.find(i => i.size == 'medium').filename;
  const imageUrl = await dispatch(getContestImageFileUrl(filename));
  return imageUrl;
};

const Image = ({image, width, screenWidth, onImageSelected}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);

  useEffect( () => { 
    (async () => {
      const url = await getUrl(image, screenWidth, dispatch);
      setUrl(url);
    })();
  }, []);

  return (
    <button className='image-btn' key={image._id} onClick={() => onImageSelected(image)}>
      <img src={url} alt="" style={{width: width}}/>
    </button>
  );
}

const FullSize = ({ selectedImage, modalOpacity, voted, screenWidth, onClose, onVote }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);

  useEffect( () => { 
    if (selectedImage) {
      (async () => {
        const url = await getUrl(selectedImage, screenWidth, dispatch);
        setUrl(url);
      })();
    }
  }, [selectedImage]);
  
  const goToProfile = (id) => {
    history.push('/profiles/' + id);
  };

  if (!selectedImage) return null;
  return (
    <div className="modal" onClick={() => onClose()} style={{opacity: modalOpacity}}>
      <button className="btn close" onClick={() => onClose()}>X</button>
      <div className='contest-image-container'>
        <div className="voted-overlay" style={{transform: `translateY(${voted ? 0 : -100}px)`}}>VOTED</div>
        {selectedImage.contestant &&
          <div className='image-header-container'>
            <button className="btn large" onClick={() => goToProfile(selectedImage.contestant._id)}>
              <p>{selectedImage.contestant.username}</p>
            </button>
          </div>
        }
        <div className='contest-image' style={{ backgroundImage: `url(${url})`}}>
        </div>
        <div className='image-footer-container'>
          {selectedImage.contest.status === 'live' &&
            <button className='lovit-button' onClick={() => onVote(selectedImage)}>
              <img src={require('../../img/lovit.png')} alt=""/>
            </button>
          }
          {selectedImage.contest.status === 'closed' && 
            <>
              {selectedImage.award &&
                <Award award={selectedImage.award} />
              }
              <Votes count={selectedImage.voteCount} />
            </>
          }
        </div>
      </div>
    </div>
  );
}

const Award = ({award}) => {
  let img;
  if (award.awardType === 'DIAMOND') img = require('../../img/awards/diamond.png');
  if (award.awardType === 'PLATINUM') img = require('../../img/awards/platinum.png');
  if (award.awardType === 'GOLD') img = require('../../img/awards/gold.png');
  if (award.awardType === 'SILVER') img = require('../../img/awards/silver.png');
  if (award.awardType === 'PINK') img = require('../../img/awards/pink.png');

  return (
    <div className='award-container'>
      <img src={img} alt=""/>
      <p>{award.awardType}</p>
    </div>
  )
}

const Votes = ({count}) => {

  return (
    <div className="votes-container">
      {count > 0 &&
        <>
          <p>{count}</p>
          <img src={require('../../img/lovit.png')} alt="" />
        </>
      }
    </div>
  )
}


const Gallery = ({images, viewType = 'contest'}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {screenHeight, screenWidth } = useSelector(state => state.ui);
  const [state, setState] = useState({
    selectedImage: null,
    modalOpacity: 0,
    voted: false,
    columnCount: 2,
  });

  const offset = viewType === 'contest' ? 220 : 0;
  const cCount = useMemo(() => screenWidth > 800 ? 3 : state.columnCount, [screenWidth, state.columnCount]);
  const colWidth = useMemo(() => screenWidth > 650 ? (screenWidth - offset) / cCount :
                    screenWidth / cCount, [screenWidth, cCount]);

  const setSelectedImage = (image) => {
    if (image) {
      setState({
        ...state,
        modalOpacity: 1,
        selectedImage: image
      });
    }
    else {
      setState({
        ...state,
        modalOpacity: 0,
        selectedImage: image 
      });
    }
  };

  const castVote = () => {
    setState({ ...state, voted: true});
    dispatch(vote(state.selectedImage));

    setTimeout(() => {
      setState({ ...state, voted: false })
      setSelectedImage(null);
    }, 1000);
  };

  const getShortestColumn = (columnHeights) => {
    let shortestColumnIndex = 0;
    columnHeights.forEach((height, i) => {
      if (height < columnHeights[shortestColumnIndex]) shortestColumnIndex = i;
    });

    return shortestColumnIndex;
  };
  
  const sortedImageColumns = useMemo(() => {
    const columnHeights = [];
    const sortedImages = [];
    console.log(images);
    for (let i = 0; i < state.columnCount; i++) {
      sortedImages.push([]);
      columnHeights.push(0);
    }
    
    images.forEach(image => {
      const [imageWidth, imageHeight] = image.images[0].dimensions;
      const height = imageWidth > imageHeight ? colWidth * 0.75 : colWidth / 0.75;
      let column = getShortestColumn(columnHeights);
      columnHeights[column] += height;

      sortedImages[column].push({image, width: colWidth});
    });
    return sortedImages;
  }, [images, state.columnCount, colWidth]);


  return (images &&
    <>
      {screenWidth <= 800 && images.length > 0 &&
        <div className="flex-center" style={{ padding: 20 }}>
          <button className="btn" onClick={() => setState({ ...state, columnCount: 2 })} style={{marginRight: 15}}>TWO COLUMNS</button>
          <button className="btn" onClick={() => setState({ ...state, columnCount: 3 })}>THREE COLUMNS</button>
        </div>
      }
      <div className='gallery-container'>
        {images && sortedImageColumns.map((column, i) => (
          <div key={i} style={{ marginRight: 2 }}>
            {column.map(columnImage => (
              <Image key={columnImage.image._id} image={columnImage.image} width={columnImage.width} screenWidth={screenWidth} onImageSelected={setSelectedImage} />
            ))}
          </div>
        ))}
        
        <FullSize {...state} onClose={() => setSelectedImage(null)} onVote={castVote} />
      </div>
    </>
  );
}

export default Gallery;