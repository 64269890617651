import { TOGGLE_CONTEST_LIST, SET_IS_MOBILE, SET_IS_CREATING_ACCOUNT, SET_SCREEN_DIMENSIONS, SET_COUNTRY, TOGGLE_DIRECTIONS, CATCH_ERROR } from '../actions/ui_actions';

export default (state = {
  showContestList: false,
  isMobile: false,
  isCreatingAccount: false,
  screenWidth: 0,
  screenHeight: 0,
  userCountry: null,
  showDirections: false,
  error: null
}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case TOGGLE_CONTEST_LIST: {
      const newState = { ...state, showContestList: !state.showContestList };
      return newState;
    }
    case SET_IS_MOBILE: {
      const newState = { ...state, isMobile: action.isMobile };
      return newState;
    }
    case SET_IS_CREATING_ACCOUNT: {
      const newState = { ...state, isCreatingAccount: action.isCreatingAccount };
      return newState;
    }
    case SET_SCREEN_DIMENSIONS: {
      const newState = { ...state, screenWidth: action.screenWidth, screenHeight: action.screenHeight };
      return newState;
    }
    case SET_COUNTRY: {
      const newState = { ...state, userCountry: action.country };
      return newState;
    }
    case TOGGLE_DIRECTIONS: {
      const newState = { ...state, showDirections: action.show };
      return newState;
    }
    case CATCH_ERROR: {
      const newState = { ...state, error: action.error };
      return newState;
    }

    default:
      return state;
  }
}