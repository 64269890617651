import React from 'react';
import ReactDOM from 'react-dom';
import './css/reset.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import Root from './components/Root';
import { getLocalUser, onUnauthorized } from './services/pinnies_api';

const user = getLocalUser();
const preloadedState = {
  user: user || {},
  entities: {
    profiles: {
      profilesList: [],
      profile: null
    },
    contests: {
      contestsList: [],
      currentContest: null
    },
    images: {
      imagesList: [],
      imageUrls: {},
      currentImage: null
    },
    interactions: {
      interactionsList: [],
      myInteractions: [],
      currentInteraction: null
    }
  }
};

const store = configureStore(preloadedState);

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
