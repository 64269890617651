import { connect } from 'react-redux';
import Winner from './winner';
import { setAwardAsViewed } from '../../actions/award_actions';

const msp = (state, ownProps) => {
  return {
    
  };
};
const mdp = dispatch => ({
  setAwardAsViewed: award => dispatch(setAwardAsViewed(award)),
});

export default connect(msp, mdp)(Winner);