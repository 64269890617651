import React from 'react';
import { useDispatch } from 'react-redux';
import { sendEmailVerification } from '../../actions/user_actions';

const EmailVerification = () => {
  const dispatch = useDispatch();
  return (
      <div className="restricted-container">
        <img src={require('../../img/logo-with-text.png')} alt="" />
        <h1>Please check your email for a verification link. After verification, please refresh this page to proceed.</h1>
        <button className="btn large" onClick={() => dispatch(sendEmailVerification())}>RESEND</button>
      </div>
    );
};

export default EmailVerification;