import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setIsCreatingAccount } from '../../actions/ui_actions';
import '../../css/splash.css';

class Splash extends Component {

  constructor(props) {
    super(props);

    this.createAccount = this.createAccount.bind(this);
  }

  render() {
    return (
      <div className="splash-container">
        <div className="main">
          <img className='logo' src={require('../../img/logo-with-text.png')} alt="" />
          <h1>THE PIN-UP SELFIE CONTEST</h1>
          <button className="btn xlarge" onClick={() => this.props.history.push('/login')}>LOGIN</button>
          <p style={{marginTop: 40}}>Welcome. Please scroll down to create an account.</p>
        </div>
        <div className="secondary">
          <div className="pink-background">
            <div className="black-triangle-down"></div>
          </div>
          <div className="row card-container pink-background">
            <div className='card' style={{ marginTop: 100, marginBottom: 0 }}>
              <h1>History</h1>
              <p>The art of pin-up has been a woven part of American culture for over 100 years. Early beginnings were
painted images of glamourous women from famous artists such as George Petty, Gil Elvgren &amp; Alberto
Vargas. As time passed, pin-ups continued to change in which photography &amp; photo shoots of
glamourous women became the progressive successor of the pastime paintings.</p>
              <p>Today’s culture helped create an entirely new style of pin-up where glamourous women take their own
photos. As tribute to the art, we welcome you to this new &amp; exciting chapter of pin-up selfies…</p>
            </div>
          </div>
          <div className="pink-background">
            <h2>There are two sides to this interactive platform...</h2>
          </div>
          <div className="row card-container pink-background">
            <div className='card'>
              <h1>Pinnies</h1>
              <p>Sexy yet classy women demonstrating the ability to take variations of pinup selfies. They are confident and respectable models radiating their female beauty and charisma in their sophisticated and seductively playful artistic photos.</p>
              <p>PINUPS + SELFIES = PINNIES</p>
            </div>
            <div className='card'>
              <h1>Voters</h1>
              <p>Cast your votes live for Pinnies with the best photos during a contest.</p>
            </div>
          </div>
          <div className="pink-triangle-down"></div>
          <div>
            <div className="row flex-center">
              <img className='award' src={require('../../img/awards/diamond.png')} alt=""/>
              <img className='award' src={require('../../img/awards/platinum.png')} alt=""/>
              <img className='award' src={require('../../img/awards/gold.png')} alt=""/>
              <img className='award' src={require('../../img/awards/silver.png')} alt=""/>
              <img className='award' src={require('../../img/awards/pink.png')} alt=""/>
            </div>
            <p style={{ paddingBottom: 100, paddingTop: 50, maxWidth: 800, margin: '0 auto' }}>Top voted Pinnies win money<br />after each contest and will compete in<br />the Pinnies Pennant<br />for<br />Grand Prize Money<br />&<br />The Pinnies Diamond Pendant.<br />Who will be the 2020 Pinnie of the Year?</p>
          </div>
          <div className="black-triangle-down pink-background"></div>
          <div className='call-to-action'>
            <h2>Take a peek & swipe through A WORLD OF GLAMOUR</h2>
          </div>
          <div className="black-background">
            <div className="pink-triangle-down"></div>
            <div className="row flex-center" style={{ height: '50vh' }}>
              <button className="btn xlarge" onClick={this.createAccount}>BECOME A PINNIE OR VOTER NOW!</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  createAccount() {
    this.props.setIsCreatingAccount(true);
    this.props.history.push('/login')
  }
}

const msp = state => ({});
const mdp = dispatch => ({
  setIsCreatingAccount: isCreatingAccount => dispatch(setIsCreatingAccount(isCreatingAccount)),
});
export default withRouter(connect(msp, mdp)(Splash));