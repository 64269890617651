import { RECEIVE_AWARDS, RECEIVE_AWARD } from '../actions/award_actions';

export default (state = [], action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_AWARDS: {
      return action.awards;
    }
    case RECEIVE_AWARD: {
      const awards = state.awards;
      const awardIdx = state.awards.findIdx(a => a._id == action.award._id);
      if (awardIdx > -1)
        awards[awardIdx] = action.award;
      else
        awards.push(action.award);
      return awards;
    }

    default: return state;
  }
}