import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import config from '../util/config';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();

export default ({store}) => (
  <Provider store={store}>
      <BrowserRouter history={browserHistory}>
        <App />
      </BrowserRouter>
  </Provider>
);