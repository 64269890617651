import { getUserProfile, getProfile, updateProfile } from '../services/pinnies_api';

export const RECEIVE_PROFILES = 'RECEIVE_PROFILES';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export const RECEIVE_MY_PROFILE = 'RECEIVE_MY_PROFILE';

export const receiveProfiles = profiles => ({
  type: RECEIVE_PROFILES,
  profiles,
});

export const receiveProfile = profile => ({
  type: RECEIVE_PROFILE,
  profile,
});

export const receiveMyProfile = profile => ({
  type: RECEIVE_MY_PROFILE,
  profile,
});

export const fetchUserProfile = (userId, isMine) => async dispatch => {
  try {
    const profile = await getUserProfile(userId);
    
    if (profile) {
      if (isMine) localStorage.setItem('profile', JSON.stringify(profile));
      
      return isMine ? dispatch(receiveMyProfile(profile)) : dispatch(receiveProfile(profile));
    }
  } catch (err) {
    console.log(err);
    alert(err);
  }
};
export const fetchProfile = (profileId) => async dispatch => {
  try {
    const profile = await getProfile(profileId);
    
    dispatch(receiveProfile(profile));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const updateAvatar = (profileId, data) => async dispatch => {
  try {
    const profile = await updateProfile(profileId, { avatar: data });

    return dispatch(receiveProfile(profile));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};
  