import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { verifyEmailAddress, sendEmailVerification } from '../../actions/user_actions';
import Loader from '../shared/loader';

const EmailVerificationSuccess = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [state, setState] = useState({
    didVerify: false,
    isSendingVerification: true
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    if (token && email) {
      dispatch(verifyEmailAddress(email, token));
    }
  }, []);

  useEffect(() => {
    let newState = state;
    if (state.isSendingVerification)
      newState = { ...newState, isSendingVerification: false };
    
    if (user.isEmailVerified)
      newState = { ...newState, didVerify: true };
      
    setState(newState);
  }, [user]);

  const resendEmailVerification = () => {
    dispatch(sendEmailVerification());
    history.replace('/email-verification');
  };

  return (state.isSendingVerification ? <Loader loading fullscreen /> :
    <div className="restricted-container">
      <img src={require('../../img/logo-with-text.png')} alt="" />
      {state.isSendingVerification ? 
      <>
        <h1>Please wait while we verify your email</h1>
      </> :
      <>
        {state.didVerify ? <>
          <h1>Your email has been successfully verified</h1>
          <button className="btn large" onClick={() => {
            if (!!user.id)
              history.replace('/audition');
            else 
              history.replace('/');
          }}>Continue</button>
        </>
        : <>
          <h1>We failed to verify your email</h1>
          <p>Please use the link we sent to your inbox to verify your email. If you cannot find it,
            check your spam folder or have a new verification email sent.</p>
          
          <button className="btn large" onClick={() => resendEmailVerification()}>RESEND</button>
        </>}
        </>}
    </div>
  )
};

export default EmailVerificationSuccess;