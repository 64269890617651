import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '../shared/avatar';
import { follow, unfollow } from '../../actions/interaction_actions';

const ProfileRow = ({profile, voteCount, onClick}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [state, setState] = useState({
    isFollowing: false
  });

  useEffect(() => {
    if (profile)
      setState({...state, isFollowing: profile.interactions && !!profile.interactions.find(i => i.interactionType == 'FOLLOW')});
  }, []);

  const toggleFollow = e => {
    e.stopPropagation();
  
    if (!state.isFollowing) {
      dispatch(follow(profile._id));
    }
    else {
      const interaction = profile.interactions.find(i => i.interactionType == 'FOLLOW');
      dispatch(unfollow(interaction));
    }
    setState({...state, isFollowing: !state.isFollowing});
  };

  return (profile ?
    <li onClick={() => onClick ? onClick() : history.push('/profiles/' + profile._id)} className='profile-row'>
      <div className='profile-row-details'>
        <Avatar profile={profile} size="small" />
        <div>
          <p>{profile.username}</p>
          {voteCount !== null && voteCount > 0 && 
            <div className="vote-count-container">
              <p>{voteCount}</p>
              <img src={require('../../img/lovit.png')} alt="" />
            </div>
          }
        </div>
      </div>
      {profile._id != user.profile._id &&
        <button className="btn" onClick={toggleFollow}>{state.isFollowing ? 'UNFOLLOW' : 'FOLLOW'}</button>
      }
    </li>
    : <li>loading</li>
  );
};

export default ProfileRow;