import { receiveProfiles } from './profile_actions';
import { getLeaderboard } from '../services/pinnies_api';

export const RECEIVE_LEADERBOARD = 'RECEIVE_LEADERBOARD';

export const receiveLeaderboard = (idol, profiles) => ({
  type: RECEIVE_LEADERBOARD,
  idol,
  profiles,
});



export const fetchLeaderboard = idol => async dispatch => {
  try {
    // const query = new Parse.Query('Profile');
    // query.equalTo('userType', 'CONTESTANT');
    // query.notEqualTo('deleted', true);
    // query.descending('totalVotes');

    // if (idol === 'pink') {
    //   query.greaterThan('totalPink', 0);
    // }
    // if (idol === 'silver') {
    //   query.greaterThan('totalSilver', 0);
    // }
    // if (idol === 'gold') {
    //   query.greaterThan('totalGold', 0);
    // }
    // if (idol === 'platinum') {
    //   query.greaterThan('totalPlatinum', 0);
    // }
    // if (idol === 'diamond') {
    //   query.greaterThan('totalDiamond', 0);
    // }

    let profiles = await getLeaderboard(idol);//await query.find();

    return dispatch(receiveLeaderboard(idol, profiles));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};