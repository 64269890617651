import React, { useState } from 'react';
import moment, { min } from 'moment';
import { useEffect } from 'react';


const Countdown = ({endDate}) => {

  const [state, setState] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });

  useEffect(() => {
    update();
    const timer = setInterval(update, 1000);

    return () => clearInterval(timer);
  }, []);

  const update = () => {
    const end = moment(endDate);
    const today = moment();
    const days = end.diff(today, 'days');
    const hours = end.diff(today, 'hours') - days * 24;
    const minutes = end.diff(today, 'minutes') - end.diff(today, 'hours') * 60;
    const seconds = end.diff(today, 'seconds') - end.diff(today, 'minutes') * 60;

    setState({...state, days, hours, minutes, seconds})
  }

  return (
    <div className="countdown-container">
      <div className="days">{state.days}<span>d</span></div>
      <div className="hours">{state.hours}<span>h</span></div>
      <div className="minutes">{state.minutes}<span>m</span></div>
      <div className="seconds">{state.seconds}<span>s</span></div>
    </div>
  );
}

export default Countdown;