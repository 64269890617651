import { useEffect, useState } from "react";
import placeholderAvatar from '../../img/placeholder-avatar.png';
import { getAvatarImageFileUrl } from '../../actions/image_actions';

const Avatar = ({profile, size = 'small'}) => {
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        const hasAvatar = !!profile.avatar;
        const isBlob = hasAvatar && profile.avatar.substring(0, 4) == 'data:';

        if (hasAvatar && !isBlob) {
            getAvatarImageFileUrl(profile.avatar);
        }
        else if (isBlob) {
            setAvatar(profile.avatar);
        }
        else if (!hasAvatar) {
            setAvatar(placeholderAvatar);
        }
    }, []);

    return (
        <div className={`avatar ${size}`}>
            <img src={avatar} alt={profile.username} />
        </div>
    );
};

export default Avatar;