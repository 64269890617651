import { getIpInfo } from '../services/pinnies_api';
export const TOGGLE_CONTEST_LIST = 'TOGGLE_CONTEST_LIST';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_CREATING_ACCOUNT = 'SET_IS_CREATING_ACCOUNT';
export const SET_SCREEN_DIMENSIONS = 'SET_SCREEN_DIMENSIONS';
export const SET_COUNTRY = 'SET_COUNTRY';
export const TOGGLE_DIRECTIONS = 'TOGGLE_DIRECTIONS';
export const REDIRECT_UI = 'REDIRECT_UI';
export const CATCH_ERROR = 'CATCH_ERROR';

const IPSTACK_KEY = process.env.REACT_APP_IPSTACK_ACCESS_KEY;
export const toggleContestList = () => ({
  type: TOGGLE_CONTEST_LIST
});

export const setIsMobile = (isMobile) => ({
  type: SET_IS_MOBILE,
  isMobile,
});

export const setIsCreatingAccount = (isCreatingAccount) => ({
  type: SET_IS_CREATING_ACCOUNT,
  isCreatingAccount,
});

export const setScreenDimensions = (screenWidth, screenHeight) => ({
  type: SET_SCREEN_DIMENSIONS,
  screenWidth, 
  screenHeight
});

export const setCountry = country => ({
  type: SET_COUNTRY,
  country
});

export const toggleDirections = show => ({
  type: TOGGLE_DIRECTIONS,
  show
});

export const setUiRedirectPath = path => ({
  type: REDIRECT_UI,
  path
});

export const setError = err => ({
  type: CATCH_ERROR,
  error: err
});

export const updateScreenDimensions = () => dispatch => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const isMobile = window.innerWidth < 650;

  dispatch(setIsMobile(isMobile));
  dispatch(setScreenDimensions(screenWidth, screenHeight));
};

export const checkCountry = () => async dispatch => {
  const ipData = await getIpInfo();
  dispatch(setCountry(ipData.country_code || null));
};

export const catchError = (err) => async dispatch => {
  dispatch(setError(err));
};

export const clearErrors = () => async dispatch => {
  dispatch(setError(null));
};