import { Component } from 'react';
import { connect } from 'react-redux';
import { updateScreenDimensions } from '../../actions/ui_actions';


class ScreenResize extends Component {

  componentDidMount() {
    this.props.updateScreenDimensions();
    window.addEventListener("resize", this.props.updateScreenDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.props.updateScreenDimensions);
  }

  render() {
    return null;
  }
}


const msp = (state, ownProps) => ({});
const mdp = dispatch => ({
  updateScreenDimensions: () => dispatch(updateScreenDimensions()),
});

export default connect(msp, mdp)(ScreenResize);