import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { searchUsernames } from '../../actions/search_actions';
import ProfileRow from '../shared/profile_row';
import Paywall from '../shared/paywall';
import Modal from '../shared/modal';
import '../../css/search.css';

const Search = () => {
  const input = useRef(null);
  const paywall = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const profiles = useSelector(state => state.entities.profiles.profilesList);
  const [state, setState] = useState({
    username: '',
    showPaywall: false,
  });

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  }, [input]);

  useEffect(() => {
    if (user.role == 'SUBSCRIBED' && paywall.current) {
      paywall.current.close();
    }
  }, [user, paywall]);

  const handleInputChange = (value) => {

      setState({ ...state, username: value})
      dispatch(searchUsernames(value.toLowerCase()));
  };

  const goToProfile = (profile) => {
    if (user.role == 'SUBSCRIBED')
        history.push('/profiles/' + profile._id);
      else
        setState({ ...state, showPaywall: true })
  };

  return (
    <div className="search-container">
      <input 
        type="text" 
        placeholder='USERNAME' 
        value={state.username.toUpperCase()} 
        ref={input}
        onChange={e => handleInputChange(e.target.value)} 
      />
      <div className="search-list">
        <ul>
        {profiles.length > 0 && profiles.map(profile => (
          <ProfileRow key={profile._id} profile={profile} onClick={() => goToProfile(profile)} />
        ))}
        </ul>

        { state.username !== '' && profiles.length === 0 && 
          <p className='no-results'>No Pinnies Found</p>
        }
      </div>

      {state.showPaywall &&
        <Modal ref={paywall} onClose={() => setState({ ...state, showPaywall: false })}>
          <Paywall />
        </Modal>
      }
    </div>
  );

}

export default Search;