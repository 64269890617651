import React, { Component } from 'react';

export default class Modal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      top: window.innerHeight,
    };

    this.close = this.close.bind(this);
  }
  
  componentDidMount() {
    setTimeout(() => {
      this.setState({top: 0});
    }, 0);
  }

  render() {
    const { hideCloseButton } = this.props;
    const { top } = this.state;
    
    return (
      <div className="modal" style={{transform: `translateY(${top}px)`}}>
        {!hideCloseButton &&
          <button className="btn close" onClick={this.close}>X</button>
        }
        {this.props.children}
      </div>
    );
  }

  close() {
    return new Promise((resolve, reject) => {
      this.setState({top: window.innerHeight})
      setTimeout(() => {
        if (this.props.onClose) this.props.onClose();
        resolve();
      }, 500);
    })
  }
}