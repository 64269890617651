import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchInteractions } from '../../actions/interaction_actions'; 
import ProfileRow from '../shared/profile_row';
import Paywall from '../shared/paywall';
import Modal from '../shared/modal';
import '../../css/search.css';

const Following = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paywall = useRef(null);
  const { profileId } = useParams();
  const profiles = useSelector(state => state.entities.interactions.interactionsList);
  const user = useSelector(state => state.user);
  const [state, setState] = useState({
    showPaywall: false,
  });

  useEffect(() => {
    dispatch(fetchInteractions(profileId));
  }, []);

  useEffect(() => {
    dispatch(fetchInteractions(profileId));
  }, [profileId]);

  const goToProfile = (profileId) => {
    if (user.role == 'SUBSCRIBED')
      history.push('/profiles/' + profileId);
    else
      setState({...state, showPaywall: true});
  };

  return (
    <div className="following-container">
      <div className="following-list">
        <ul>
          {profiles.map(profile => (
            <ProfileRow key={profile._id} profile={profile} onClick={() => goToProfile(profile._id)} />
          ))}
        </ul>

        {profiles.length === 0 &&
          <p className='no-results'>You are not following any Pinnies</p>
        }
      </div>


      {state.showPaywall &&
        <Modal ref={paywall} onClose={() => setState({...state, showPaywall: false })}>
          <Paywall />
        </Modal>
      }
    </div>
  );
};

export default Following;