import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../components/login/login';

export const AuthRoute = ({ component: Component, path, exact }) => {
  const loggedIn = useSelector(state => !!state.user.id);
  return <Route path={path} exact={exact} render={(props) => (
    !loggedIn ? (
      <Component {...props} />
    ) : (
      <Redirect to="/contests" />
    )
  )} />
    };

export const ProtectedRoute = (props) => {
  const { component: Component, path, exact } = props;
  const loggedIn = useSelector(state => !!state.user.id);
  
  return <Route path={path} exact={exact} render={(props) => loggedIn ? 
      <Component {...props} />
     : 
        <Login />
      
  } />
};