import { searchProfiles } from '../services/pinnies_api';
import { receiveProfiles } from './profile_actions';

export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';

export const receiveSearchResults = usernames => ({
  type: RECEIVE_SEARCH_RESULTS,
  usernames,
});



export const searchUsernames = (username) => async dispatch => {
  if (username === '') return dispatch(receiveProfiles([]));
  
  try {
    const profiles = await searchProfiles(username);
    const usernames = profiles.map(p => p.username);
    
    dispatch(receiveProfiles(profiles));
    return dispatch(receiveSearchResults(usernames));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};