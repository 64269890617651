import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../util/cropImage';
import loadImage from 'blueimp-load-image';

const ImageCropper = (props) => {

    const [state, setState] = useState({
      image: null,
      zoom: 1,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: null,
      aspect: props.aspect || 4 / 3,
      circle: props.circle || false,
      cropSize: props.cropSize || null,
    });


  useEffect(() => {
    // required for correct orientation
    loadImage(props.image, (img) => {
        const data = img.toDataURL('image/jpeg');
        setState({...state, image: data});
      },
      { 
        orientation: true,
        canvas: true 
      }
    );
  }, []);

  const onCropChange = crop => {
    setState({...state, crop });
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setState({...state, croppedAreaPixels });
  }

  const onZoomChange = zoom => {
    setState({...state, zoom });
  }
  
  const changeAspect = () => {
    const aspect = state.aspect === 4/3 ? 3/4 : 4/3;
    setState({...state, aspect})
  }

  const crop = async () => {
    const croppedImage = await getCroppedImg(
      state.image,
      state.croppedAreaPixels
    )

    props.onComplete(croppedImage, state.aspect);
  }
  
  return (
    <div className='cropper-container'>
      <Cropper
        image={state.image}
        crop={state.crop}
        zoom={state.zoom}
        aspect={state.aspect}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        cropShape={state.circle ? 'round' : 'rect'}
        cropSize={state.cropSize}
      />

      <div className="cropper-button-container">
        <button className="btn large" onClick={props.cancel}>CANCEL</button>
        {!props.lockAspect && <button className="btn large" onClick={changeAspect}>{state.aspect === 4/3 ? 'PORTRAIT' : 'LANDSCAPE'}</button>}
        <button className="btn large" onClick={crop}>CONFIRM</button>
      </div>
    </div>
  );
}

export default ImageCropper;