import { RECEIVE_IMAGES, RECEIVE_IMAGE, RECEIVE_IMAGE_URL, RECEIVE_PROFILE_IMAGES } from '../actions/image_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_IMAGES: {
      console.log(action.images);
      return { ...state, imagesList: action.images};
    }
    case RECEIVE_IMAGE: {
      return { ...state, currentImage: action.image};
    }
    case RECEIVE_PROFILE_IMAGES: {
      return { ...state, profileImages: action.images };
    }
    case RECEIVE_IMAGE_URL: {
      return { ...state, imageUrls: {...state.imageUrls, [action.key]: action.url}};
    }

    default: return state;
  }
}