import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleContestList } from '../../actions/ui_actions';
import { useEffect } from 'react';

const Contest = ({contest, isSelected, onSelect}) => {
  const classes = 'contest-list-item' + (isSelected ? " active" : '')

  return (
    <li key={contest._id} className={classes} onClick={onSelect}>
      <h3>{contest.name}</h3>
      <p>{contest.subtitle}</p>
    </li>
  );
}

const ContestList = ({ contests, contestId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const show = useSelector(state => state.ui.showContestList);
  const isMobile = useSelector(state => state.ui.isMobile);

  const selectContest = (contestId) => {
      history.push('/contests/' + contestId)
      if (isMobile) dispatch(toggleContestList());
  };

  return (
    <ul className={'contest-list' + (isMobile ?  !show ? ' mobile hidden' : ' mobile' : '')}>
      <li className='contest-list-item header'><h3>CONTESTS</h3></li>
      {contests && contests.map(contest => (
        <Contest contest={contest} isSelected={contestId == contest._id} onSelect={() => selectContest(contest._id)} />
      ))}
    </ul>
  );

}

export default ContestList;
