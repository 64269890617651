import { connect } from 'react-redux';
import Leaderboard from './leaderboard';
import { fetchLeaderboard } from '../../actions/leaderboard_actions';

const msp = (state, ownProps) => {
  return {
    leaderboard: state.entities.leaderboard,
    profiles: state.entities.profiles,
    me: state.entities.profiles[state.user.username],
    isSubscribed: state.user.isSubscribed,
  };
};
const mdp = dispatch => ({
  fetchLeaderboard: idol => dispatch(fetchLeaderboard(idol)),
});

export default connect(msp, mdp)(Leaderboard);