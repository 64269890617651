import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { AuthRoute, ProtectedRoute } from '../util/route_util';
import { ToastContainer, toast } from 'react-toastify';
import Nav from './nav/nav_container';
import Modal from './shared/modal';
import ScreenResize from './util/screen_resize';
import { fetchCurrentUser, logout } from '../actions/user_actions';
import { fetchInteractions } from '../actions/interaction_actions';
import { fetchProfileImages } from '../actions/image_actions';
import { fetchAwards } from '../actions/award_actions';
import { toggleContestList, checkCountry, toggleDirections, setUiRedirectPath } from '../actions/ui_actions';
import { onUnauthorized } from '../services/pinnies_api';

import Splash from './splash/splash';
import Contests from './contests/contests';
import Login from './login/login';
import Profile from './profile/profile';
import Search from './search/search';
import Following from './following/following';
import Leaderboard from './leaderboard/leaderboard_container';
import Winner from './winner/winner_container';
import Audition from './audition/audition_container';
import SubscriptionConfirmation from './subscription/subscription_confirmation';
import Restricted from './misc/restricted';
import Directions from './misc/directions';
import EmailVerification from './misc/email_verification';
import EmailVerificationSuccess from './misc/email_verification_success';
import PasswordReset from './login/password_reset';

import 'react-toastify/dist/ReactToastify.css';
import '../css/app.css';

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const profile = useSelector(state => state.entities.profiles.profile);
  const newAwards = useSelector(state => state.entities.awards.filter(award => !award.viewed));
  const isMobile = useSelector(state => state.ui.isMobile);
  const showContestList = useSelector(state => state.ui.showContestList);
  const userCountry = useSelector(state => state.ui.userCountry);
  const showDirections = useSelector(state => state.ui.showDirections);
  const redirect = useSelector(state => state.ui.redirectPath);
  const error = useSelector(state => state.ui.error);

  const publicRoutes = [
    '/',
    '/login',
    '/ev'
  ];

  // Setup the Pinnies API as early as possible
  onUnauthorized((response) => {
    // TODO: If the user is unauthorized we should log them out of the app
    dispatch(logout());
    history.push('/login', { referrer: `${location.pathname}${location.search}`});
    return response.json();
  });

  const [state, setState] = useState({
    showDirections: false,
    showAccount: profile && profile.userType === 'VOTER' && !localStorage.getItem('directions'),
    isVerifyingEmail: location.pathname === '/ev',
  });
  
  useEffect(() => {
    dispatch(checkCountry());
    if (user.id) {
      dispatch(fetchCurrentUser());
      // TODO: Implement Interactions
      //props.fetchInteractions(user.id);
      
    }
  }, []);

  useEffect(() => {
    if (redirect != null) {
      history.push(redirect);
      dispatch(setUiRedirectPath(null));
    }
  }, [redirect]);

  useEffect(() => {
    if (!user.id) {
      return;
    }
    /*if (!user._id && !publicRoutes.includes(location.pathname)) { //(location.pathname !== '/' || location.pathname !== '/login')) {
      console.log('This should be handled by the AuthRoute');
      history.push('/');
    }
    else if (state.isVerifyingEmail && location.pathname !== '/ev') {
      console.log('----------------- because we were verifying email and redirected to login');
      setState({ ...state, isVerifyingEmail: false });
    }
    else */
    if (typeof user.isEmailVerified != 'undefined' && !user.isEmailVerified && location.pathname !== '/email-verification' && location.pathname !== '/ev') {
      history.push('/email-verification');
    }
    else if (user.isApproved && !localStorage.getItem('directions') && profile) {
      history.replace('/user/' + profile.username);
      dispatch(toggleDirections(true));
      localStorage.setItem('directions', true);
    }
    else if (userCountry != 'US' && profile?.userType == 'CONTESTANT' && location.pathname !== '/restricted') {
      history.push('/restricted');
    }
  }, [user]);

  useEffect(() => {
    if (profile && profile.userType === 'CONTESTANT') {
      dispatch(fetchProfileImages(profile));
      dispatch(fetchAwards(profile));
    }

    if (profile && profile.userType === 'VOTER' && !localStorage.getItem('directions')) {
      dispatch(toggleDirections(true));
      localStorage.setItem('directions', true);
    }
  }, [profile]);

  useEffect(() => {
    if (!error) {
      toast.dismiss();
    }
    else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        closeOnClick: true,
        delay: 1000,
        theme: 'colored'
      });
    }
  }, [error]);

  const hiddenNavPaths = [
    '/audition',
    '/login',
    '/restricted',
    '/email-verification',
    '/ev',
    '/'
  ];

  const showNav = user.id && !hiddenNavPaths.includes(location.pathname);
  const redirectToAudition = profile && profile.userType === 'CONTESTANT' && !user.isApproved && user.isEmailVerified && location.pathname !== '/audition' && !state.isVerifyingEmail;
  const redirectToContests = profile && (profile.userType === 'CONTESTANT' && user.isApproved || profile.userType === 'VOTER') && user.isEmailVerified && location.pathname === '/audition';

  return (
    <div>
      <ScreenResize />
      
      {userCountry != 'US' && profile?.userType == 'CONTESTANT' && location.pathname !== '/restricted' &&
        <Redirect to={'/restricted'} />
      } 
      {user.isEmailVerified && location.pathname === '/email-verification' &&
        <Redirect to={'/'} />
      }

      {redirectToAudition &&
        <Redirect to={'/audition'} />
      }

      {redirectToContests &&
        <Redirect to={'/contests'} />
      }

      {showNav && 
        <Nav showAccount={state.showAccount} />
      }
      
      <Route exact path="/" component={Splash} />
      <AuthRoute exact path="/login" component={Login} />
      <ProtectedRoute exact path="/contests" component={Contests} />
      <ProtectedRoute path="/contests/:contestId" component={Contests} />
      <ProtectedRoute exact path="/profiles/:profileId" component={Profile} />
      <ProtectedRoute path="/search" component={Search} />
      <ProtectedRoute path="/profiles/:profileId/following" component={Following} />
      <ProtectedRoute path="/leaderboard" component={Leaderboard} />
      <ProtectedRoute path="/audition" component={Audition} />
      <ProtectedRoute path="/subscriptions/stripe/confirm" component={SubscriptionConfirmation} />
      <Route path="/restricted" component={Restricted} />
      <ProtectedRoute path="/email-verification" component={EmailVerification} />
      <Route path="/ev" component={EmailVerificationSuccess} />
      <Route path="/password-reset" component={PasswordReset} />
      {/* <ProtectedRoute path="/directions" component={Directions} /> */}

      {newAwards.length > 0 &&
        <Modal ref={winnerModal => this.winnerModal = winnerModal} hideCloseButton={true}>
          <Winner award={newAwards[0]} onClose={this.closeWinnerModal} />
        </Modal>
      }

      {isMobile && location.pathname.includes('/contests') &&
        <div className='toggle-contest-list-btn' onClick={toggleContestList} style={{ transform: `translate(${showContestList ? 0 : -220}px` }}>
          <div className={'arrow' + (showContestList ? ' left' : ' right')} />
        </div>
      }

      {showDirections &&
        <Modal onClose={() => toggleDirections(false)}>
          <Directions isContestant={profile.userType === 'CONTESTANT'} />
        </Modal>
      }

      
      <ToastContainer />
    </div>
  );
}

const closeWinnerModal = async () => {
  await this.winnerModal.close();
}

export default App;
