import { RECEIVE_PROFILE_IMAGE_IDS, RECEIVE_PROFILE_IMAGE_ID } from '../actions/image_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PROFILE_IMAGE_IDS: {
      const newState = { ...state, [action.username]: action.imageIds };
      return newState;
    }
    case RECEIVE_PROFILE_IMAGE_ID: {
      const newState = { ...state };
      newState[action.username].unshift(action.imageId);
      return newState;
    }

    default: return state;
  }
}