import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScores } from '../../actions/scores_actions';
import ProfileRow from '../shared/profile_row';
import '../../css/scores.css';

const Scores = ({contest}) => {
  const dispatch = useDispatch();
  const myProfile = useSelector(state => state.entities.profiles.profile);
  const scores = useSelector(state => state.entities.scores.scoresList);

  useEffect(() => {
    if (contest)
      dispatch(fetchScores(contest));
  }, []);

  useEffect(() => console.log(scores), [scores]);
  
  const myImage = useMemo(() => !!scores ? scores.filter(score => score.contestant._id == myProfile._id)[0] : {}, [scores]);
  const height = useMemo(() =>  myImage ? `calc(100% - 80px)` : '100vh', [myImage]);

  return (scores ?
    <div className={`scores-container`}>
      <div className="wrapper" style={{height}}>
        <div>
          <h1>SCORES</h1>
          {scores.map(image => <ProfileRow key={image._id} profile={image.contestant} voteCount={image.totalVotes} />)}
        </div>

      </div>
      {myImage &&
        <div className="my-placement-container">
          <ProfileRow key={myImage._id} profile={myImage.contestant} voteCount={myImage.totalVotes} />
        </div>
      }
    </div>
    : <></>
  );
};

export default Scores;