import React, { Component } from 'react';

export default class Loader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show: this.props.loading,
      opacity: this.props.loading ? 1 : 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loading && this.props.loading) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ opacity: 1 });
      }, 100);
    }
    if (prevProps.loading && !this.props.loading) {
      this.setState({opacity: 0});
      setTimeout(() => {
        this.setState({show: false});
      }, 500);
    }
  }
  
  render() {
    if (!this.state.show) return null;

    const { fullscreen } = this.props;
    const containerClasses = "loader-container" + (fullscreen ? ' fullscreen' : '');
    
    return (
      <div className={containerClasses} style={{opacity: this.state.opacity}}>
        <div className="loader">LOADING...</div>
      </div>
    );
  }
}