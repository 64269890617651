import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Nav from './nav';
import { toggleContestList } from '../../actions/ui_actions';

const msp = (state, ownProps) => ({
  isMobile: state.ui.isMobile,
  screenWidth: state.ui.screenWidth,
  username: state.user.username,
  userType: state.user.username && state.entities.profiles[state.user.username] ? state.entities.profiles[state.user.username].userType : null,
});
const mdp = dispatch => ({
  toggleContestList: () => dispatch(toggleContestList()),
});

export default withRouter(connect(msp, mdp)(Nav));