import React from 'react';

export default props => {

  return (
    <div className="numbered-list-container">
      {props.children.map((item, i) => (
        <div key={i} className="numbered-list-item-container">
          <div className='number'>{i + 1}</div>
          {item}
        </div>
      ))}
    </div>
  );
}