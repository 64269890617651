import React from 'react';

export default props => {
  return (
    <>
      <h1 style={{ textAlign: 'center' }}>PHOTO RULES</h1>
      <p style={{ whiteSpace: 'pre-line' }}>{`
TYPES OF SELFIES:

1.  Arms Length Selfie
2.  Mirror Selfie
3.  Use of a Selfie Stick
4.  Self-Timer Selfie -  This shot requires a little more thought due to angle and reflection but is attainable.  The concept is least used for selfies as of today but can be beneficial when competing in the app.  The camera must be visible in a mirror within the shot to prove that someone else did not take the image.  You are the photographer.  See example.

`}</p>
      <div className="flex-center">
        <img src={require('../../img/mirror_ex.jpg')} style={{ width: 'calc(100% - 30px)', maxWidth: 700, height: 'auto' }} />
      </div>
      <p style={{ whiteSpace: 'pre-line' }}>{`

Photo MUST be compliant to Contest theme if a theme is described.
 
Photo MUST be in either a .JPG, .JPEG, or .PNG file.
 
Photo MUST be in HIGH resolution and in good lighting condition.
 
Photo MUST have been taken on or after the Contest submission start date.
 
Photo MUST be taken with a clean background; no visible toilets, bidets, trash, etc.
 
Photo MUST be wholly original, be the Contestant's own creation and Contestant warrants that she has secured all necessary rights relating to its use.
 
Photo MUST NOT include logos not owned or licensed by Pinnies that belong to or are copyrighted/trademarked by third parties.  This includes the cell phone logo on your phone (APPLE, LG, SAMSUNG, ETC).  PLEASE USE A CELL PHONE CASE OR OTHER MEANS NECESSARY TO COVER THE LOGO OR YOUR ENTRY WILL BE DENIED.
 
Photo MUST NOT have been altered to enhance the photo including but not limited to: body/face/eye/skin/hair altering or editing, live stickers, photo stickers, etc. by any 3rd party software.
 
Photo MUST NOT have been previously entered in any competition or contest or won any award prior to submission in this Contest and Photo must not have been previously posted, published, performed, or otherwise exhibited (this includes social media outlets).
 
Photo MUST NOT include materials (language, phrases, quotations, artwork, products or brand names not owned or licensed by Pinnies, etc.), belonging to or copyrighted by third parties.
 
Photo MUST NOT contain any unsolicited or unauthorized advertising, promotional materials, watermarks, or any other form of solicitation. 
 
Photo MUST NOT contain the first name, last name, phone number, address, email address, or social media username of the Contestant or any other person.
 
Photo MUST NOT contain or depict any objectionable, unlawful, defamatory, threatening, abusive, harassing, hateful, or discriminatory material and must be otherwise fit for publication/posting/uploading.
 
Photo MUST NOT contain any obscene, vulgar or sexually explicit content (penetration, masturbation, genitals, bare nipples, etc.)
 
Photo MUST NOT contain any material that: (a) would give or threaten to give rise to criminal or civil liability; (b) encourages conduct that constitutes a criminal offense; (c) encourages or provides instructional information about illegal activities; (d) encourages politics, political viewpoints, political parties or political candidates.
 
Photo MUST NOT contain any references to alcohol, tobacco, vapes, e-cigarettes, drug paraphernalia, firearms, sex toys or any description or representation thereof.
 
Photo MUST NOT include any other people or pets.
							`}</p>
    </>
  );
}