import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import moment from 'moment';
import Loader from '../shared/loader';
import '../../css/login.css';
import { login, signup, sendPasswordReset } from '../../actions/user_actions';
import { useEffect } from 'react';

const Tabs = ({userType, country, onChange}) => {
  return (
    <div className="tabs-container" style={{marginBottom: 15}}>
      <div className={"tab" + (userType === 'VOTER' ? ' active' : '')} onClick={() => onChange('VOTER')}>
        <p>VOTER</p>
      </div>
      {country == 'US' &&
        <div className={"tab" + (userType === 'CONTESTANT' ? ' active' : '')} onClick={() => onChange('CONTESTANT')}>
          <p>CONTESTANT</p>
        </div>
      }

    </div>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userCountry = useSelector(state => state.ui.userCountry);
  const isCreatingAccount = useSelector(state => state.ui.isCreatingAccount);
  const [state, setState] = useState({
    email: '',
    username: '',
    password: '',
    dob: '',
    isSignup: isCreatingAccount,
    userType: userCountry != 'US' ? 'VOTER' : null,
    errors: {},
    forgotPassword: false,
    loading: false,
  });

  const changeUserType = (userType) => {
    setState({...state, userType});
  };

  const toggleCreate = () => {
    setState({...state, isSignup: !state.isSignup});
  };

  const toggleForgot = () => {
    setState({...state, forgotPassword: !state.forgotPassword});
  };

  const handleValueChange = (e, field) => {
    let value = e.target.value;

    if (field === 'dob') {
      var prevNumChars = state.dob.length;
      var numChars = value.length;
      if ((numChars === 2 || numChars === 5) && prevNumChars < numChars) {
        value += '/';
      }
    }
    
    setState({...state, [field]: value });
  };

  const doResetPassword = async (e) => {
    e.preventDefault();
    setState({...state, loading: true});
    await dispatch(sendPasswordReset(state.email));
    setState({...state, loading: false});
  };

  const doLogin = async (e) => {
    e.preventDefault();
    setState({...state, loading: true});
    let redirect = null;

    if (location.state?.referrer)
      redirect = location.state.referrer;
    if (location.state?.search)
      redirect += '?' + location.state.search;

    await dispatch(login(state.username, state.password, redirect));
    setState({...state, loading: false});
  };
  
  const doSignup = async (e) => {
    e.preventDefault();

    if (!state.userType) {
      alert('Please choose a user type');
      return;
    }

    if (!validate()) return;
    
    setState({...state, loading: true});
    await dispatch(signup(state.email, state.username, state.password, state.dob, state.userType));
    
    setState({...state, loading: false});
  };
  
  const validate = () => {
    const { email, username, password, dob } = state;
    const errors = {};
    if (password.length < 1) errors.password = true;

    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!re.test(email)) errors.email = true;

    const illegalUsernameChars = /[^\w\.]/ // allow letters, numbers, and underscores
    if (username.length < 2 || illegalUsernameChars.test(username)) errors.username = true;

    const date = new Date(dob)
    if (isNaN(date.getTime()) || Date.now() - date.getTime() < 1000 * 60 * 60 * 24 * 365.25 * 18) errors.dob = true;

    setState({...state, errors});
    return Object.keys(errors).length === 0;
  };
  
  return (
    <div className="login-container">
      <img src={require('../../img/logo-with-text.png')} alt=""/>
      
      <form onSubmit={state.forgotPassword ? doResetPassword : state.isSignup ? doSignup : doLogin}>
        {(state.isSignup || state.forgotPassword) &&
          <input 
            type="email" 
            placeholder='EMAIL' 
            value={state.email} 
            onChange={(e) => handleValueChange(e, 'email')} 
            className={state.isSignup && state.errors.email ? 'error' : ''} 
            autoCorrect="off" 
            autoCapitalize="off"
          />
        }
        {!state.forgotPassword &&
          <input 
            type="text" 
            placeholder='USERNAME' 
            value={state.username} 
            onChange={(e) => handleValueChange(e, 'username')} 
            className={state.isSignup && state.errors.username ? 'error' : ''} 
            autoCorrect="off" 
            autoCapitalize="off"
          />
        }
        {!state.forgotPassword &&
          <input 
            type="password" 
            placeholder='PASSWORD' 
            value={state.password} 
            onChange={(e) => handleValueChange(e, 'password')} 
            className={state.isSignup && state.errors.password ? 'error' : ''} 
          />
        }
        {state.isSignup && 
          <div className='input-container'>
            <input 
              type='text' 
              placeholder='Date of birth - MM/DD/YYYY'
              value={state.dob} 
              onChange={(e) => handleValueChange(e, 'dob')} 
              className={state.isSignup && state.errors.dob ? 'error' : ''} 
            />
            <div className='input-header'>DATE OF BIRTH</div>
          </div>
        }

        {state.isSignup && 
        <>
          {userCountry == 'US' && 
          <>
            <p className="red-text" style={{ padding: 15 }}>PLEASE CHOOSE ONE</p>
            <Tabs userType={state.userType} onChange={changeUserType} country={userCountry} />
          </>
          }

          <p className="red-text" style={{padding: 15}}>YOU MUST BE OVER 18 TO SIGNUP</p>
          <p>By using Pinnies, you have agreed to <a href="https://media.pinniesusa.com/terms-of-use.pdf" target='blank'>TERMS OF USE</a></p>
          <p><a href="https://media.pinniesusa.com/privacy-policy.pdf" target='blank'>PRIVACY POLICY</a></p>
        </>
        }
        <input className='btn' type="submit" value={state.forgotPassword ? 'RESET PASSWORD' : state.isSignup ? 'CREATE ACCOUNT' : 'LOGIN'}/>
      </form>

      <a className='toggle' onClick={state.forgotPassword ? toggleForgot : toggleCreate}>{!state.isSignup && !state.forgotPassword ? 'CREATE ACCOUNT' : 'LOGIN'}</a>

      {!state.forgotPassword && !state.isSignup && 
        <a className='toggle forgot' onClick={toggleForgot}>FORGOT PASSWORD?</a>
      }

      <Loader loading={state.loading} fullscreen={true} />
    </div>
  );
};

export default Login;