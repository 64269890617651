import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../shared/loader';
import { logout, deleteAccount, updateUser } from '../../actions/user_actions';
import { toggleDirections } from '../../actions/ui_actions';
import '../../css/account.css';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      email: this.props.user.email,
      password: '',
      loading: false,
    };
    this.onEditButton = this.onEditButton.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
  }

  render() {
    const { isEditing, email, password, loading } = this.state;
    const { user } = this.props;
    const touUrl = 'https://media.pinniesusa.com/terms-of-use.pdf';
    const ppUrl = 'https://media.pinniesusa.com/privacy-policy.pdf';

    return (Object.keys(user).length > 0 ? (
      <div className="account-container">
        <div className="wrapper">
          <h1>ACCOUNT</h1>

          <p>Username: {user.profile.username}</p>
          <p>Email: {user.email}</p>
          <p>Subscription: {user.role == 'SUBSCRIBED' ? 'ACTIVE' : 'INACTIVE'}</p>

          {isEditing &&
            <div>
              <input type="text" placeholder='EMAIL' value={email} onChange={this.handleInput('email')} />
              <input type="password" placeholder='NEW PASSWORD' value={password} onChange={this.handleInput('password')} />
            </div>
          }

          <button className="btn large" onClick={this.onEditButton}>{isEditing ? 'SAVE' : 'EDIT ACCOUNT'}</button>

          <br/>
          <br/>
          <br/>

          <button className="btn large full-width" onClick={() => window.open('https://pinnies.undergroundshirts.com/collections/pinnies', 'blank')}>SHOP</button>

          {this.props.showFollowing && 
            <button className="btn large full-width" onClick={() => this.goToFollowing()}>FOLLOWING</button>
          }
          <button className="btn large full-width" onClick={() => this.props.toggleDirections(true)}>VIEW GAME DIRECTIONS</button>
          <button className="btn large full-width" onClick={() => window.open(touUrl,'_blank')}>TERMS OF USE</button>
          <button className="btn large full-width" onClick={() => window.open(ppUrl,'_blank')}>PRIVACY POLICY</button>
          <button className="btn large full-width" onClick={() => this.props.logout()}>LOGOUT</button>
          <button className="btn large full-width" onClick={this.deleteAccount}>DELETE ACCOUNT</button>

          <Loader loading={loading} fullscreen={true} />
        </div>
      </div>
      ) : <></>
    );
  }

  goToFollowing() {
    this.props.history.push('/following');
    this.props.close();
  }

  handleInput(field) {
    return (e) => {
      this.setState({[field]: e.target.value});
    }
  }

  async onEditButton() {
    const { isEditing, email, password } = this.state;
    const { user, updateUser } = this.props;

    if (isEditing) {
      const updatedUser = {id: user.objectId};
      if (email !== user.email) updatedUser.email = email;
      if (password !== '') updatedUser.password = password;

      this.setState({ loading: true });
      await updateUser(updatedUser);
      this.setState({ isEditing: false, loading: false });
    }
    else this.setState({ isEditing: true });
  }

  deleteAccount() {
    if (window.confirm('Are you sure? Deleting your account is permanent!')) {
      this.props.deleteAccount();
    }
  }
}


const msp = state => ({
  user: state.user,
})
const mdp = dispatch => ({
  toggleDirections: show => dispatch(toggleDirections(show)),
  logout: () => dispatch(logout()),
  deleteAccount: () => dispatch(deleteAccount()),
  updateUser: (user) => dispatch(updateUser(user)),
});

export default withRouter(connect(msp, mdp)(Account));