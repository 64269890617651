import { RECEIVE_LEADERBOARD } from '../actions/leaderboard_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_LEADERBOARD: {
      const newState = { ...state, [action.idol]: action.profiles };
      return newState;
    }

    default: return state;
  }
}