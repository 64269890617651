export const RECEIVE_AWARDS = 'RECEIVE_AWARDS';
export const RECEIVE_AWARD = 'RECEIVE_AWARD';

export const receiveAwards = awards => ({
  type: RECEIVE_AWARDS,
  awards
});
export const receiveAward = award => ({
  type: RECEIVE_AWARD,
  award
});



export const fetchAwards = profile => async dispatch => {
  try {
    // const query = new Parse.Query('Award');
    // query.equalTo('profile', new Parse.Object('Profile', {id: profile.objectId}));
    // query.limit(1000);

    let awards = [];//await query.find();
    awards = awards.map(award => award.toJSON());

    return dispatch(receiveAwards(awards));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const setAwardAsViewed = award => async dispatch => {
  try {
    const newAward = {}; //await Parse.Cloud.run('setAwardAsViewed', {award});
    return dispatch(receiveAward(newAward));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};