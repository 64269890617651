import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContests } from '../../actions/contest_actions';
import ContestList from './contest_list';
import ContestDetails from './contest_details';
import Paywall from '../shared/paywall';
import '../../css/contest.css';

const Contests = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {contestId} = useParams();
  const profile = useSelector(state => state.entities.profiles.profile);
  const contests = useSelector(state => { return state.entities.contests.contestsList;});
  const showContestList = useSelector(state => state.ui.showContestList);
  const isMobile = useSelector(state => state.ui.isMobile);
  const isSubscribed = useSelector(state => state.user.role == 'SUBSCRIBED');

  const [state, setState] = useState({
    loaded: false,
  });

  useEffect(() => {
    if (profile) {
      dispatch(fetchContests(profile.userType === 'CONTESTANT'));
      setState({ ...state, loaded: true });
    }
  }, []);

  useEffect(() => {
    if (profile && (!contests || contests.length === 0)) {
      dispatch(fetchContests(profile.userType === 'CONTESTANT'));
      setState({ ...state, loaded: true });
    }
  }, [profile]);

  useEffect(() => {
    if (!!contests && contests.length > 0 && !contestId) {
      history.replace('/contests/' + contests[0]._id);
    }
  }, [ contests ]);

  const isHidden = isMobile && !showContestList;
  const spacerClasses = 'contest-list-spacer' + (isHidden ? ' hidden' : '');
  
  return (
    <div className='contest-container'>
      {!isMobile &&
        <div className={spacerClasses} />
      }
      <ContestList contests={contests} />
      {isSubscribed && contestId && 
        <ContestDetails contestId={contestId} />
      }
      {!isSubscribed && contests && contests.length > 0 &&
        <Paywall />
      }
      {state.loaded && contests.length === 0 &&
        <div className='center-text' style={{margin: '0 auto', padding: 40, maxWidth: 700}}>
          <p className='sub-text'>FIRST OFFICIAL CONTEST COMING SOON.</p>
          {profile && profile.userType === 'CONTESTANT' ?
            <p className='sub-text'>You will be alerted via email to submit a contest entry. Thank you for your patience.</p>
          :  
            <p className='sub-text'>You will be alerted via email when the contest is live for voting. Thank you for your patience.</p>
          }
        </div>
      }
    </div>
  );
  
};

export default Contests;