import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ContestEntry from './contest_entry';
import { fetchContestEntries } from '../../actions/image_actions';
import { uploadContestEntry, agreeToContestRules } from '../../actions/contest_actions';


const msp = (state, ownProps) => {
  const username = state.user.username;
  const myImageIds = state.entities.profileImageIds[username] || []
  const myImages = myImageIds.map(id => state.entities.images[id]);
  const entries = myImages.filter(image => image.contest.objectId === ownProps.match.params.contestId);

  return {
    entries,
    user: state.user,
  };
};
const mdp = dispatch => ({
  uploadContestEntry: (data, position, contest) => dispatch(uploadContestEntry(data, position, contest)),
  agreeToContestRules: contest => dispatch(agreeToContestRules(contest)),
});
export default withRouter(connect(msp, mdp)(ContestEntry));