import { receiveProfiles } from './profile_actions';
import { getContestImages, getMyContestImages, getContestImageUrl, getProfileImages, getProfileImageUrl } from '../services/pinnies_api';

export const RECEIVE_IMAGES = 'RECEIVE_IMAGES';
export const RECEIVE_IMAGE = 'RECEIVE_IMAGE';
export const RECEIVE_IMAGE_URL = 'RECEIVE_IMAGE_URL';
export const RECEIVE_PROFILE_IMAGES = 'RECEIVE_PROFILE_IMAGES';
export const RECEIVE_CONTEST_IMAGE_IDS = 'RECEIVE_CONTEST_IMAGE_IDS';
export const RECEIVE_PROFILE_IMAGE_IDS = 'RECEIVE_PROFILE_IMAGE_IDS';
export const RECEIVE_PROFILE_IMAGE_ID = 'RECEIVE_PROFILE_IMAGE_ID';

export const receiveImages = (images) => ({
  type: RECEIVE_IMAGES,
  images,
});
export const receiveImage = (image) => ({
  type: RECEIVE_IMAGE,
  image,
});
export const receiveImageUrl = (filename, imageUrl) => ({
  type: RECEIVE_IMAGE_URL,
  key: filename,
  url: imageUrl
});
export const receiveContestImages = (contestId, imageIds) => ({
  type: RECEIVE_CONTEST_IMAGE_IDS,
  contestId,
  imageIds,
});
export const receiveProfileImages = (images) => ({
  type: RECEIVE_PROFILE_IMAGES,
  images
});
export const receiveProfileImage = (username, imageId) => ({
  type: RECEIVE_PROFILE_IMAGE_ID,
  username,
  imageId,
});

export const clearContestImages = () => async dispatch => {
  dispatch(receiveImages([]));
};

export const fetchContestImages = contestId => async dispatch => {
  try {
    let images = await getContestImages(contestId);
    const profiles = [];
    images = images.map(image => {
      if (image.profile) {
        profiles.push(image.profile);
      }
      return image;
    });
    const imageIds = images.map(image => image._id);

    dispatch(receiveImages(images));
    dispatch(receiveProfiles(profiles));
    return dispatch(receiveContestImages(contestId, imageIds));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const fetchContestEntries = contestId => async dispatch => {
  try {
    dispatch(receiveContestImages([]));

    let images = await getMyContestImages(contestId);
    const profiles = [];
    images = images.map(image => {
      if (image.profile) {
        profiles.push(image.profile);
      }
      return image;
    });
    const imageIds = images.map(image => image._id);

    dispatch(receiveImages(images));
    return dispatch(receiveContestImages(contestId, imageIds));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const fetchProfileImages = profile => async dispatch => {
  try {
    let images = await getProfileImages(profile._id);//await Parse.Cloud.run('fetchContestantImages', { profile });
    
    const imageIds = images.map(image => image._id);

    return dispatch(receiveProfileImages(profile.username, imageIds));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const fetchPendingImages = profile => async dispatch => {
  try {
    let images = [];//await Parse.Cloud.run('fetchPendingContestEntries', {});
    const imageIds = images.map(image => image._id);

    dispatch(receiveImages(images));
    return dispatch(receiveProfileImages(profile.username, imageIds));
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const getContestImageFileUrl = filename => async dispatch => {
  try {
    let {imageUrl} = await getContestImageUrl(filename);
    dispatch(receiveImageUrl(imageUrl));
    return imageUrl;
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

export const getAvatarImageFileUrl = filename => async dispatch => {
  try {
    let {imageUrl} = await getProfileImageUrl(filename);
    dispatch(receiveImageUrl(imageUrl));
    return imageUrl;
  } catch (err) {
    console.log(err);
    alert(err);
  }
};