import { RECEIVE_INTERACTIONS, RECEIVE_MY_INTERACTIONS, RECEIVE_INTERACTION, REMOVE_INTERACTION } from '../actions/interaction_actions';

export default (state = {
  follow: {},
}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_INTERACTIONS: {
      return { ...state, interactionsList: action.interactions };
    }
    case RECEIVE_MY_INTERACTIONS: {
      return { ...state, myInteractions: action.interactions };
    }
    case RECEIVE_INTERACTION: {
      return { ...state, currentInteraction: action.interaction };
    }
    case REMOVE_INTERACTION: {
      const newInteractions = state.interactionsList;
      const interactionIdx =newInteractions.findIndex(i => i._id == action.interaction._id);
      if (interactionIdx > -1)
        newInteractions.splice(interactionIdx, 1);
      return { ...state, interactionsList: newInteractions};
    }

    default: return state;
  }
}