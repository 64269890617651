import { combineReducers } from 'redux';
import ProfileReducer from './profiles_reducer';
import ContestReducer from './contests_reducer';
import ImageReducer from './images_reducer';
import ContestImageIdReducer from './contest_image_ids_reducer';
import ProfileImageIdReducer from './profile_image_ids_reducer';
import SearchReducer from './search_reducer';
import InteractionsReducer from './interactions_reducer';
import ScoresReducer from './scores_reducer';
import LeaderboardReducer from './leaderboard_reducer';
import AwardsReducer from './awards_reducer';
import AuditionsReducer from './auditions_reducer';


export default combineReducers({
  contests: ContestReducer,
  profiles: ProfileReducer,
  images: ImageReducer,
  contestImageIds: ContestImageIdReducer,
  profileImageIds: ProfileImageIdReducer,
  search: SearchReducer,
  interactions: InteractionsReducer,
  scores: ScoresReducer,
  leaderboard: LeaderboardReducer,
  awards: AwardsReducer,
  auditions: AuditionsReducer,
});