import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { fetchProfile, updateAvatar } from '../../actions/profile_actions';
import { fetchProfileImages } from '../../actions/image_actions';
import Modal from '../shared/modal';
import Account from '../misc/account';
import Gallery from '../shared/gallery';
import ImageCropper from '../shared/cropper';
import Loader from '../shared/loader';
import '../../css/profile.css';

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profileId } = useParams();
  const filePicker = useRef();
  const user = useSelector(state => state.user);
  const profile = useSelector(state => state.entities.profiles.currentProfile);
  const images = useSelector(state => state.entities.images.profileImages);
  const [state, setState] = useState({
    showAccount: false,
    selectedImage: null,
    loading: false,
    loadedImages: false,
    isMyProfile: false
  });

  // Load profile on first load
  useEffect(() => {
    if (!profile) {
      dispatch(fetchProfile(profileId));
      setState({...state, loadedImages: true });
    }
  }, []);
  
  // Reload profile and profile images if requesting a new profile
  useEffect(() => {
    dispatch(fetchProfile(profileId));
  }, [profileId]);

  // Hide the loader once profile is loaded
  useEffect(() => {
    if (profile)
      dispatch(fetchProfileImages(profile));
    console.log(user);
    setState({...state, loading: false, loadedImages: false, selectedImage: null, isMyProfile: user.profile._id == profileId});
  }, [profile]);

  // Hide the loader once profile images are loaded
  useEffect(() => {
    setState({...state, loadedImages: true});
  }, [images]);

  const Awards = ({ totalDiamond, totalPlatinum, totalGold, totalSilver, totalPink }) => {

    return (
      <div className="awards-container">
        {totalDiamond > 0 && 
          <div className='award'>
            <img src={require('../../img/awards/diamond.png')} alt="" />
            <p>DIAMOND</p>
            <p style={{color: 'white'}}>{totalDiamond}</p>
          </div>
        }
        {totalPlatinum > 0 && 
          <div className='award'>
            <img src={require('../../img/awards/platinum.png')} alt="" />
            <p>PLATINUM</p>
            <p style={{color: 'white'}}>{totalPlatinum}</p>
          </div>
        }
        {totalGold > 0 && 
          <div className='award'>
            <img src={require('../../img/awards/gold.png')} alt="" />
            <p>GOLD</p>
            <p style={{color: 'white'}}>{totalGold}</p>
          </div>
        }
        {totalSilver > 0 && 
          <div className='award'>
            <img src={require('../../img/awards/silver.png')} alt="" />
            <p>SILVER</p>
            <p style={{color: 'white'}}>{totalSilver}</p>
          </div>
        }
        {totalPink > 0 && 
          <div className='award'>
            <img src={require('../../img/awards/pink.png')} alt="" />
            <p>PINK</p>
            <p style={{color: 'white'}}>{totalPink}</p>
          </div>
        }
      </div>
    );
  }

  const Votes = ({ totalVotes }) => {

    return (
      <div className="profile-votes-container">
        {totalVotes > 0 &&
          <>
            <p>{totalVotes}</p>
            <img src={require('../../img/lovit.png')} alt="" />
          </>
        }
      </div>
    );
  }

  const onImageSelect = (e) => {
    if (!e.target.files[0]) return;
    setState({...state, selectedImage: e.target.files[0] });
  }

  const onCropComplete = async (data) => {
    setState({...state, loading: true})
    dispatch(updateAvatar(profileId, data));
  }


  const goToFollowing = () => {
    history.push(`${profileId}/following`);
  }

  if (!profile) return null;


  return (
    <div className="profile-container">
      {state.isMyProfile &&
        <button className={'btn-underline account'} onClick={() => setState({...state, showAccount: true})}>ACCOUNT</button>
      }
      <button className={'btn-underline shop'} onClick={() => window.open('https://pinnies.undergroundshirts.com/collections/pinnies', 'blank')}>SHOP</button>

      <div className="avatar">
        <img src={profile.avatar ? profile.avatar : ''} alt="" className=""/>
        {state.isMyProfile && 
          <div className="edit-avatar" onClick={() => state.isMyProfile && filePicker.current.click()}>EDIT</div>
        }
      </div>
      <h1>{profile.username}</h1>
      <Awards profile={profile} />
      <Votes profile={profile} />
      {state.isMyProfile &&
        <button className='btn large' onClick={() => goToFollowing()}>FOLLOWING</button>
      }
      <br/>
      <Gallery images={images} />

      {state.loadedImages && images.length === 0 && state.isMyProfile && 
        <p className="sub-text">Enter contests to build your portfolio.</p>
      }
      {state.loadedImages && images.length === 0 && !state.isMyProfile && 
        <p className="sub-text">This Pinnie has not entered any contests.</p>
      }

      {state.selectedImage &&
        <ImageCropper
          image={state.selectedImage}
          onComplete={onCropComplete}
          cancel={() => this.setState({...state, selectedImage: null})}
          aspect={1}
          lockAspect={true}
          circle={true}
          cropSize={{width: 300, height: 300}}
        />
      }

      {state.showAccount &&
        <Modal onClose={() => setState({...state, showAccount: false})}>
          <Account/>
        </Modal>
      }

      {state.loading && <Loader fullscreen={true} />}

      {/* hidden file input so that the modal can programatically open the file picker */}
      <label className="fileContainer" style={{ display: 'none' }}>
        <input accept='image/*;capture=camera' ref={filePicker} type="file" onChange={onImageSelect} />
      </label>
    </div>
  );

};

export default Profile;