import React, { Component } from 'react';

export default class Restricted extends Component {
  render() {
    return (
      <div className="restricted-container">
        <img src={require('../../img/logo-with-text.png')} alt="" />
        <h1>Sorry, Pinnies is unavailable in your country.</h1>
      </div>
    );
  }
}

