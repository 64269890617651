import { RECEIVE_CONTEST_IMAGE_IDS } from '../actions/image_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CONTEST_IMAGE_IDS: {
      const newState = { ...state, [action.contestId]: action.imageIds };
      return newState;
    }

    default: return state;
  }
}