import { RECEIVE_USER, LOGOUT } from '../actions/user_actions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_USER: {
      const newState = {...state, ...action.user};
      return newState;
    }
    case LOGOUT: {
      return {};
    }

    default:
      return state;
  }
}